<template>
  <div>
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="topo-header centro">
        <botao-voltar class="voltar" nomeRota="Cargo"/>
        <h1>Visualizar Cargo</h1>
      </div>
      <div class="form centro">
        <div class="dados">
          <fieldset>
            <div class="dados">
              <div class="flexbox-1">
                <p>Nome: 
                  <span>{{ cargo.nome }}</span>
                </p>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="bt-editar" v-if="temPermissao.admin">
          <div class="div-center">
            <div :id="cargo.id">
              <a :href="'/cargo/' + cargo.id + '/edit'">
                <button id="botao-editar">
                  Editar 
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer
          :createdByLabel="'Criado por'"
          :criadoPor="cargo.criado_por"
          :lastUpdatedByLabel="'Última atualização feita por'"
          :atualizadoPor="cargo.atualizado_por"
          :emLabel="' em '"
          :SistemaLabel="'Sistema'"
          :created_at="cargo.created_at"
          :updated_at="cargo.updated_at"
        />
      </div>
    </div>  
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import CargoApi from "../../services/CargoApi";
  import { parseISO, format } from "date-fns"
  import "../../assets/stylesheets/exibir_conteudo_ato.scss";
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirCargo",
    props: {
      cargo: Object,
      temPermissao: Boolean,
    },
    data() {
      return{
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        showModalCancelar: false,
        showModalPublicar: false,
        maxCount: 500,
        remainingCountObservacao: 500,
        hasError: false,
        resposta: null,
        observacao: null,
        status: null,
        acao_analise: '',
        motivo_cancelamento: '',
        mensagemTopo: '',
        mensagemAbaixo: '',
        r: false,

        dadosCargo: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      }
    },
    created() {
      this.CargoApi = new CargoApi();
      this.carregando = false;
    },
    methods: {
      formatarDataHora: function(value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
    },  
    components: { 
      BotaoVoltar,
      Footer, 
    },
  };
</script>

<style lang="scss" scoped>
</style>