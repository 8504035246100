<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <div v-if="erro">
                <SemPermissao></SemPermissao>
            </div>
            <template v-if="!erro">
                <ExibirConvenio :convenioCota="convenioCota" :temPermissao="temPermissao" />
            </template>
        </div>
    </div>
</template>

<script>
    import ConvenioCotaApi from "../../services/ConvenioCotaApi";
    import SemPermissao from "../SemPermissao.vue"
    //import ErroResultados from "../../components/ErroResultados";
    import ExibirConvenio from "../../components/convenios_cotas/ExibirConvenio.vue";
    import metaDados from "../../helpers/metaDados";
    import Auth from '../../services/Auth';

    export default {
        name: "ConveniosCotasShow",
        metaInfo() {
            if (!this.convenioCota) {
                return {};
            }

            let title = this.convenioCota.nome;
            const description = this.convenioCota.nome;

            return {
                title,
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "description",
                        name: "description",
                        property: "description",
                        content: description,
                    },
                    {
                        vmid: "og:description",
                        property: "og:description",
                        content: description,
                    },
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    {
                        vmid: "twitter:description",
                        name: "twitter:description",
                        property: "twitter:description",
                        content: description,
                    },
                    {
                        vmid: "twitter:title",
                        name: "twitter:title",
                        property: "twitter:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                temPermissao: null,
                carregando: true,
                erro: false,
                convenioCota: null,
            };
        },
        components: {
            //ErroResultados,
            SemPermissao,
            ExibirConvenio,
        },
        created() {
            this.convenioCotaApi = new ConvenioCotaApi();
            let auth = new Auth();
            this.carregarConvenioCota();
            this.temPermissao = auth.temPermissao;
        },
        watch: {
            $route: function (to, from) {
                if (to.params.id != from.params.id) {
                    this.carregarConvenioCota();
                }
            },
        },
        methods: {
            carregarConvenioCota: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.convenioCotaApi.cancelTokens.getConvenioCota) {
                        this.convenioCotaApi.cancelTokens.getConvenioCota.cancel();
                    }

                    const dadosConvenioCota = await this.convenioCotaApi.getConvenioCota(
                        this.$route.params.id
                    );
                    this.convenioCota = dadosConvenioCota

                    this.carregando = false;
                } catch (err) {
                    if (this.convenioCotaApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);

                    this.erro = true;
                    this.carregando = false;
                    this.convenioCota = null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>