<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>UF</th>
          <th>Sigla</th>
          <th>Esfera</th>
          <th>Poder</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="orgao in orgaosFormat">
          <template>
            <tr :key="orgao.id">
              <td data-label="Nome:" class="topo-card">
                <span>{{ orgao.nome }}</span>
              </td>
              <td data-label="UF:">
                <span>{{ orgao.uf }}</span>
              </td>
              <td data-label="Sigla:">
                <div v-if="orgao.sigla !== null">
                  <span>{{ orgao.sigla }}</span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </td>
              <td data-label="Esfera:">
                <span>{{ orgao.esfera }}</span>
              </td>
              <td data-label="Poder:">
                <span>{{ orgao.poder }}</span>
              </td>
              <td class="coluna-acoes-tabela">
                <!-- Visualizar -->
                <div class="icone-tabela">
                  <router-link
                    :to="{
                      name: 'OrgaoShow',
                      params: { id: orgao.id },
                    }"
                  >
                    <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar"/>
                  </router-link>
                </div> 
              </td>
            </tr>
          </template>
        </template>
        
        <tr v-if="!orgaos || orgaos == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {parseISO, format} from "date-fns"

  import MsgErroFormularios from "../MsgErroFormularios";
  import OrgaoApi from "../../services/OrgaoApi"

  export default {
    name: "TabelaResultadosOrgaos",
    props: {
      orgaos: Array,
      orgao: Object,
    },
    created() {
      this.orgaoApi = new OrgaoApi();
    },
    components: {
      MsgErroFormularios,
    },
    data() {
      return {
        msgErros: {
          orgao: '',
          default: '',
        },
        erro: false,
        orgaosFormat: this.orgaos.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      formatarData: function(value) {
        if(value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            min-width: 10rem;
            text-align-last: center;

            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }
    }
  }
</style>