<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Código</th>
          <th>Ativo</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="status in statusFormat" :key="status.id">
          <td data-label="Nome:">
            <span>{{ status.nome }}</span>
          </td>
          <td data-label="Código:">
            <span>{{ status.codigo }}</span>
          </td>
          <td data-label="Status ativo:">
            <span v-if="status.ativo == true">Sim</span>
            <span v-else>Não</span>
          </td>
          <td class="coluna-acoes-tabela">
            <!-- Visualizar -->
            <div class="icone-tabela">
              <router-link :to="{
                name: 'StatusShow',
                params: { id: status.id },
              }">
                <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
              </router-link>
            </div>
            <!-- Editar -->
            <div class="icone-tabela">
              <router-link :to="{
                name: 'StatusEdit',
                params: { id: status.id },
              }">
                <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar" />
              </router-link>
            </div>
            <!-- Excluir -->
            <span class="icone-tabela" type="button" @click="opcoesExcluir(status.id)">
              <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir" />
            </span>
          </td>
          <div class="modal-exclusao" :id="status.id">
            <div class="modal-mask">
              <div class="modal-container">
                <div class="modal-header">
                  <h2>Deseja mesmo excluir esse Status?</h2>
                </div>
                <div class="modal-body">
                  <p>Dados do Status:</p>
                  <div class="dados">
                    <div class="texto-centralizado">
                      Nome: <span>{{ status.nome }}</span>
                    </div>
                    <div class="texto-centralizado">
                      <div v-if="status.ativo == true">
                        Ativo: <span>Sim</span>
                      </div>
                      <div v-else>
                        Ativo: <span>Não</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn-confirmar-exclusao" @click="excluirLinha(status.id)">Sim</button>
                  <button class="btn-cancelar-exclusao" @click="opcoesExcluir(status.id)">Não</button>
                </div>
              </div>
            </div>
          </div>
        </tr>
        <tr v-if="!statuses || statuses == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"
  import MsgErroFormularios from "../MsgErroFormularios";
  import StatusApi from "../../services/StatusApi";

  export default {
    name: "TabelaResultadosStatus",
    props: {
      statuses: Array,
      status: Object,
    },
    components: {
      MsgErroFormularios,
    },
    created() {
      this.statusApi = new StatusApi();
    },
    data() {
      return {
        msgErros: {
          status: '',
        },
        erro: false,
        statusFormat: this.statuses.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      opcoesExcluir(statusId) {
        let modalExclusao = document.getElementsByClassName('modal-exclusao')

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == statusId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible"
            } else {
              modalExclusao[i].style.visibility = "hidden"
            }
          }
        }
      },
      async excluirLinha(statusId) {
        this.opcoesExcluir(statusId)

        try {
          this.carregando = true;
          this.erro = false;

          if (this.statusApi.cancelTokens.destroyStatus) {
            this.statusApi.cancelTokens.destroyStatus.cancel();
          }

          await this.statusApi.destroyStatus(statusId);
          window.location.reload();
          
        } catch (err) {
          if (this.statusApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;

          this.msgErros.status = "Não foi possível excluir este status, pois ele está vinculado a uma cessão/disposição."
        }
      },
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>