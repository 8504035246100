<template>
  <div>
    <div class="tela-login">
      <div class="right">
        <h1>Redefinir Senha</h1>
        <form @submit.prevent="handleSubmit">
          <div class="item">
            <label>Nova senha:</label>
            <input type="password" name="senha" id="senha" class="form-control" placeholder="Senha"
              v-model="senha" />
            <br>

            <label>Confirmação da nova senha:</label>
            <input type="password" name="confirmacao_senha" id="confirmacao_senha" class="form-control"
              placeholder="Confirmação da senha" v-model="confirmacao_senha" />
          </div>

          <button type="submit" class="btn-enviar">Enviar</button>

          <div class="notificacao" id="notificacao">
            <p class="mensagem" id="mensagem"></p>
          </div>
        </form>
      </div>
      <div class="footer-mobile">
        <img title="Logo da Casa Civil e Estado de Goiás" src="../../assets/imagens/logo-casa-bco.png" class="logo"
          alt="Logo da Casa Civil e Estado de Goiás" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import metaDados from "../../helpers/metaDados";

  export default {
    name: 'RedefinirSenha',
    metaInfo() {
      return {
        title: "Movimentação Servidores",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Movimentação Servidores - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        login: '',
        senha: '',
        confirmacao_senha: '',
        token: '',
      }
    },
    methods: {
      async handleSubmit() {
        const data = {
          login: this.$router.currentRoute.query.login,
          senha: this.senha,
          confirmacao_senha: this.confirmacao_senha,
          token: this.$router.currentRoute.query.token,
        };

        if (this.senha == this.confirmacao_senha) {
          this.mensagemSucesso();
          axios.post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/sessoes/redefinir_senha`, data)
            .then(
              res => {
                this.$router.push({
                  path: "/sessoes/autenticar",
                  params: {
                    response: res,
                  }
                });
              }
            ).catch(
              err => {
                console.log(err)
                this.mensagemFalha2();
              }
            )
        } else {
          data.senha = ''
          data.confirmacao_senha = ''
          this.mensagemFalha();
        }
      },
      mensagemSucesso() {
        document.getElementById("notificacao").style.visibility = "visible"
        document.getElementById("mensagem").style.color = "green"
        document.getElementById("mensagem").innerHTML = "Sua senha foi redefinida com sucesso!"
        document.getElementById("mensagem").style.display = "block"
      },
      mensagemFalha() {
        document.getElementById("notificacao").style.visibility = "visible"
        document.getElementById("mensagem").style.color = "red"
        document.getElementById("mensagem").innerHTML = "As senhas precisam coincidir!"
        document.getElementById("mensagem").style.display = "block"
      },
      mensagemFalha2() {
        document.getElementById("notificacao").style.visibility = "visible"
        document.getElementById("mensagem").style.color = "red"
        document.getElementById("mensagem").innerHTML = "Ocorreu um erro na mudança das senhas. Tente novamente!"
        document.getElementById("mensagem").style.display = "block"
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>