<template>
    <div>
        <div v-if="erro">
            <SemPermissao></SemPermissao>
        </div>
        <template v-if="!erro">
            <ServidorForm :servidor="null" :cargosServidorForm="null" />
        </template>
    </div>
</template>

<script>
    import ServidorForm from '../../components/servidores/ServidorForm.vue';
    import SemPermissao from "../SemPermissao.vue"
    
    //import ErroResultados from "../../components/ErroResultados";

    export default {
        name: "ServidorNew",
        data() {
            return {
                erro: false,
            };
        },
        components: {
            SemPermissao,
            ////ErroResultados,
            ServidorForm,
        },
    };
</script>

<style lang="scss" scoped>
</style>