<template>
    <div>
        <div v-if="erro">
            <SemPermissao></SemPermissao>
        </div>
        <template v-if="!erro">
            <CessaoForm :cessao="null" />
        </template>
    </div>
</template>

<script>
    import CessaoForm from '../../components/cessoes/CessaoForm.vue';
    import SemPermissao from "../SemPermissao.vue"
    //import ErroResultados from "../../components/ErroResultados";

    export default {
        name: "CessaoNew",
        data() {
            return {
                erro: false,
            };
        },
        components: {
            //ErroResultados,
            SemPermissao,
            CessaoForm,
        },
    };
</script>

<style lang="scss" scoped>
</style>