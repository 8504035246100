<template>
    <div class="contador_tempo">
        <div class="conteudo">
            <p>Tempo para sessão expirar: {{ this.expiram_em }} minutos</p>
        </div>
    </div>
</template>

<script>
    import HomeApi from "../services/HomeApi";

    export default {
        created() {
            this.homeApi = new HomeApi();
            this.carregarExpiradoEm();
        },
        data() {
            return {
                expiram_em: null,
            };
        }, 
        methods: {
            carregarExpiradoEm: async function () {
                try {
                    const tempo_expiracao = await this.homeApi.getExpiraEm()
                    this.expiram_em = tempo_expiracao
                } catch (err) {
                    this.erro =
                        typeof err === "string"
                        ? err
                        : "Houve um erro ao tentar acessar o sistema. Por favor tente novamente";
                }
            },
        },
        mounted() {
            // Reduz o tempo restante a cada minuto
            this.contagemRegressiva = setInterval(() => {
                if (this.expiram_em > 0) {
                    this.expiram_em--;
                } else {
                    clearInterval(this.contagemRegressiva); // Para a contagem quando chegar a 0
                }
            }, 60000);

            //Renovar sessão toda vez que clicar ou rolar a página
            window.addEventListener('click', this.carregarExpiradoEm);
            window.addEventListener('scroll', this.carregarExpiradoEm);
        },
        beforeDestroy() {
            clearInterval(this.contagemRegressiva); // Limpa o intervalo quando o componente for destruído
            window.removeEventListener('click', this.carregarExpiradoEm);
            window.removeEventListener('scroll', this.carregarExpiradoEm);
        },
    };
</script>

<style lang="scss" scoped>
    .conteudo {
        p {
            margin: 0;
            font-size: .8rem;
            color: #efefef;
            padding-left: .5rem;
        }
    }
</style>