<template>
  <div>
    <div v-if="autenticando" class="modal">
      <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
      <label>Aguarde. Autenticando.</label>
    </div>
  </div>
</template>

<script>
  import Auth from "../../services/Auth";
  import { rotaInicial } from "../../router/helpers";
  import metaDados from "../../helpers/metaDados";

  export default {
    name: "Autenticar",
    metaInfo() {
      return {
        title: "Movimentação Servidores",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content: "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Portal - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        autenticando: false,
      };
    },
    async created() {
      await this.autenticar();
    },
    methods: {
      async autenticar() {
        try {
          this.autenticando = true;

          const dados = this.obterParametrosURL();
          
          if (dados.token != null && dados.iv != null) {
            await this.realizarAutenticacao(dados);
          } else {
            this.redirecionarPortal();
          }
        } catch (err) {
          console.error('Erro ao autenticar usuário:', err);
          this.redirecionarNaoEncontrado();
        } finally {
          this.autenticando = false;
        }
      },
      obterParametrosURL() {
        const urlParams = new URLSearchParams(window.location.search);
        const dadosParametros = {
          token: urlParams.get('token'),
          iv: urlParams.get('iv'),
        }
        
        return dadosParametros
      },
      async realizarAutenticacao(dados) {
        await this.$auth.login(dados);

        const redirectPath = this.$route.query.redirect || rotaInicial().name;
        this.$router.push({ name: redirectPath });
      },
      redirecionarPortal() {
        const URL = `${process.env.VUE_APP_PORTAL_URL}/sessoes/autenticar`;
        window.location.replace(URL);
      },
      redirecionarNaoEncontrado() {
        this.$router.push({ path: '/nao-encontrado' });
      }
    },
    computed: {
      $auth() {
        return new Auth();
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>