<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cargo in cargosFormat" :key="cargo.id">
          <td data-label="Nome:" class="topo-card">
            <span>{{ cargo.nome }}</span>
          </td>
          <td class="coluna-acoes-tabela">
            <!-- Visualizar -->
            <div class="icone-tabela">
              <router-link
                :to="{
                  name: 'CargoShow',
                  params: { id: cargo.id },
                }"
              >
                <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar"/>
              </router-link>
            </div>
          </td>
        </tr> 
        <tr v-if="!cargos || cargos == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {parseISO, format} from "date-fns"

  import MsgErroFormularios from "../MsgErroFormularios";
  import CargoApi from "../../services/CargoApi"

  export default {
    name: "TabelaResultadosCargos",
    props: {
      cargos: Array,
      cargo: Object,
    },
    created() {
      this.CargoApi = new CargoApi();
    },
    components: {
      MsgErroFormularios,
    },
    data() {
      return {
        msgErros: {
          cargo: '',
          default: '',
        },
        erro: false,
        cargosFormat: this.cargos.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      formatarData: function(value) {
        if(value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            min-width: 10rem;
            text-align-last: center;

            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }
    }
  }
</style>