<template>
  <div class="div-conteudo">
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="index centro">
        <div class="topo-index">
          <h1>Cargos</h1>
          <router-link v-if="temPermissao.admin" :to="{
            name: 'CargoNew',
          }">
            <span>+</span> Novo Cargo
          </router-link>
        </div>
        <div class="caixa-pesquisa">
          <h2 @click="tooglePesquisa()">
            Pesquisa
            <span>
              <template v-if="!exibirPesquisa">&#9650;</template>
              <template v-else>&#9660;</template>
            </span>
          </h2>
          <transition name="slide">
            <form @submit.prevent="fazerBusca" v-if="!exibirPesquisa">
              <div>
                <div class = "row3-data">
                  <div class="item">
                    <label> Nome: </label>
                    <input type="text" placeholder="Digite o nome" v-model="filtro.nome" />
                  </div>
                </div>
              </div>
              <div class="botoes-pesquisa">
                <div class="bt-pesquisa">
                  <button title="Pesquisar" type="submit">
                    <img src="../../assets/imagens/pesquisa.png" class="pesquisa" alt=" " />
                    Pesquisar
                  </button>
                </div>
                <div class="bt-limpar">
                  <button title="Limpar" @click="limparFiltro()">
                    <img src="../../assets/imagens/icon-eraser.png" class="limpar" alt=" " />
                    Limpar Filtros
                  </button>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="n-div">
          <div class="nresultados" v-show="!carregando">
            <label>Total de Resultados: {{ this.totalResultadosTexto }}</label>
          </div>
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página </label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div v-if="temPermissao.admin || temPermissao.gpd" class="container-tabela">
          <TabelaResultadosCargos :cargos="cargos" :cargo="cargo"
            v-if="!carregando && !erro && !erroFiltro" @excluir-linha="excluirLinha">
          </TabelaResultadosCargos>
        </div>
        <div v-else class="container-tabela">
          <TabelaResultadosCargosUsuario :cargos="cargos" :cargo="cargo"
            v-if="!carregando && !erro && !erroFiltro" @excluir-linha="excluirLinha">
          </TabelaResultadosCargosUsuario>
        </div>
        <paginate v-if="!carregandoFiltro && !carregando" v-model="pagina" :page-count="pageCount"
              :click-handler="mudouPagina" :prev-text="'Anterior'" :next-text="'Próxima'" :container-class="'className'"
              active-class="active-page"></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import Paginate from "vuejs-paginate";
  import tryParseInt from "../../helpers/tryParseInt";
  import metaDados from "../../helpers/metaDados";
  import TabelaResultadosCargos from "../../components/cargos/TabelaResultadosCargos.vue";
  import TabelaResultadosCargosUsuario from "../../components/cargos/TabelaResultadosCargosUsuario.vue";
  import CargoApi from "../../services/CargoApi";
  import Auth from '../../services/Auth';

  export default {
    name: "Cargo",
    metaInfo() {
      return {
        title: "Cargos",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa de todos os cargos realizados.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa de todos os cargos realizados.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Cargos - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        temPermissao: null,
        cargos: [],
        cargo: null,
        carregando: true,
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        filtro: {
          nome: null,
        },
        exibirPesquisa: true,
        carregandoFiltro: false,
        erroFiltro: false,
      };
    },
    components: {
      TabelaResultadosCargos,
      TabelaResultadosCargosUsuario,
      Paginate,
    },
    created() {
      this.cargoApi = new CargoApi();
      let auth = new Auth();
      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarCargo();
      this.temPermissao = auth.temPermissao;
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.nome != from.query.nome;
        if (
          from.name == "Cargo" &&
          to.name == "Cargo" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarCargo();
        }
      },
    },
    methods: {
      tooglePesquisa: function () {
        this.exibirPesquisa = !this.exibirPesquisa;
      },
      async excluirLinha(cargoId) {
        try {
          this.carregando = true; 
          this.erro = false;

          if (this.cargoApi.cancelTokens.destroyCargo) {
            this.cargoApi.cancelTokens.destroyCargo.cancel();
          }

          await this.cargoApi.destroyCargo(cargoId);

          this.carregarCargo();
          this.carregarQtdPorPagina();
          this.carregarValoresFiltro();

        } catch (err) {
          if (this.cargoApi.isCancel(err)) {
            return;
          }
          console.log("excluirCargo", err);

          this.carregando = false;
          this.erro = true;
        }
      },
      fazerBusca() {
        this.$router.push({
          name: "Cargo",
          query: {
            ...this.filtro,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      mudouPagina: function (page) {
        this.pagina = page;

        const filtro = {
          nome: this.getParam("nome"),
        };

        this.$router.push({
          name: "Cargo",
          query: {
            ...filtro,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      carregarValoresFiltro: async function () {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false
        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
        }
      },
      carregarCargo: async function () {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina = this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);

          this.filtro = {
            nome: this.getParam("nome"),
          };

          if (this.cargoApi.cancelTokens.getCargos) {
            this.cargoApi.cancelTokens.getCargos.cancel();
          }

          let data = await this.cargoApi.getCargos({
            ...this.filtro,
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });

          this.cargos = data.resultados;
          this.totalResultados = data.total_resultados;
          if (data.total_resultados >= 1000) {
            this.totalResultadosTexto = (data.total_resultados / 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          } else {
            this.totalResultadosTexto = data.total_resultados;
          }

          this.carregando = false;
        } catch (err) {
          if (this.cargoApi.isCancel(err)) {
            return;
          }
          console.log("carregarCargo", err);
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function () {
        this.tooglePesquisa();
        this.filtro = {
          nome: undefined,
        };
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_cargo",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_cargo");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>