<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="cargo in cargosFormat">
          <template>
            <tr :key="cargo.id">
              <td data-label="Nome:" class="topo-card">
                <span>{{ cargo.nome }}</span>
              </td>
              <td class="coluna-acoes-tabela">
                <!-- Visualizar -->
                <div class="icone-tabela">
                  <router-link
                    :to="{
                      name: 'CargoShow',
                      params: { id: cargo.id },
                    }"
                  >
                    <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar"/>
                  </router-link>
                </div>
                <!-- Editar -->
                <div class="icone-tabela">
                  <router-link
                    :to="{
                      name: 'CargoEdit',
                      params: { id: cargo.id },
                    }"
                  >
                    <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar"/>
                  </router-link>
                </div>
                <!-- Excluir -->
                <span class="icone-tabela" type="button" @click="opcoesExcluir(cargo.id)">
                  <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir"/>
                </span>  
              </td>
              <div class="modal-exclusao" :id="cargo.id">
                <div class="modal-mask">
                  <div class="modal-container">
                    <div class="modal-header">
                      <h2>Deseja mesmo excluir esse Cargo?</h2>
                    </div>
                    <div class="modal-body">
                      <p>Dados do Órgão:</p>
                      <div class="dados">
                        <div class="texto-centralizado">
                          Nome: <span>{{ cargo.nome }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button class="btn-confirmar-exclusao" @click="excluirLinha(cargo.id)">Sim</button>
                      <button class="btn-cancelar-exclusao" @click="opcoesExcluir(cargo.id)">Não</button>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </template>
        </template>
        
        <tr v-if="!cargos || cargos == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {parseISO, format} from "date-fns"

  import MsgErroFormularios from "../MsgErroFormularios";
  import CargoApi from "../../services/CargoApi"

  export default {
    name: "TabelaResultadosCargos",
    props: {
      cargos: Array,
      cargo: Object,
    },
    created() {
      this.CargoApi = new CargoApi();
    },
    components: {
      MsgErroFormularios,
    },
    data() {
      return {
        msgErros: {
          cargo: '',
          default: '',
        },
        erro: false,
        cargosFormat: this.cargos.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      opcoesExcluir(cargoId) {
        let modalExclusao = document.getElementsByClassName('modal-exclusao')

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == cargoId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible"
            } else {
              modalExclusao[i].style.visibility = "hidden"
            }
          }
        }
      },
      async excluirLinha(cargoId) {
        this.opcoesExcluir(cargoId)

        try {
          this.carregando = true;
          this.erro = false;

          if (this.CargoApi.cancelTokens.destroyCargo) {
            this.CargoApi.cancelTokens.destroyCargo.cancel();
          }

          await this.CargoApi.destroyCargo(cargoId);
          window.location.reload();

        } catch (err) {
          if (this.CargoApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;

          this.msgErros.cargo = "Não foi possível excluir o cargo, pois ele está vinculado a um servidor.";
        }
      },
      formatarData: function(value) {
        if(value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .15rem .5rem 0.8rem;
              vertical-align: middle;
              font-size: 1rem;
            }

            td:nth-child(1) {
              min-width: 12rem;
            }

            td:nth-child(2) {
              text-align: center;
            }

            td:nth-child(3) {
              text-align: center;
            }

            td:nth-child(4) {
              text-align: center;
            }

            td:nth-child(5) {
              text-align: justify;
              text-align: center;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            text-align-last: center;
          }
        }
      }
    }
  }
</style>