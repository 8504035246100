<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Cargo" />
            <h1>{{ cargoForm.id ? "Editar" : "Novo" }} Cargo</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>                
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="flexbox-1">
                            <div class="item">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="cargoForm.nome" />
                            </div>
                        </div>
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ cargoForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import CargoApi from "../../services/CargoApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";

    export default {
        name: "cargoForm",
        props: {
            cargo: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                count: -1,
                count1: null,
                filess: null,
                carregando: true,
                erro: false,
                msgErros: {
                    nome: '',
                    default: '',
                },
                cargoForm: this.cargo
                    ? {
                        id: this.cargo.id,
                        nome: this.cargo.nome,
                    }
                    : {
                        id: null,
                        nome: null,
                    },
            };
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
        },
        created() {
            this.CargoApi = new CargoApi();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },
            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;

                let flag = this.validarForm(this.cargoForm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    this.msgErros = '';

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.CargoApi.cancelTokens.setCargo) {
                        this.CargoApi.cancelTokens.setCargo.cancel();
                    }

                    let data;
                    data = await this.CargoApi.setCargo(
                        this.cargoForm
                    );

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "CargoShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                } catch (err) {
                    if (this.CargoApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            validarForm(cargoForm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros.nome = ''
                this.msgErros.default = ''

                //Nome não pode estar em branco
                if (cargoForm.nome == null || cargoForm.nome == '') {
                    this.msgErros.nome = "O campo nome não foi preenchido.";
                    flag = 1;
                }

                return flag;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
        },

    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
</style>
