<template>
  <div class="div-conteudo">
    <div class="fora">
      <div class="index centro">
        <div class="topo-index">
          <h1>Sem Permissão</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SemPermissao",
    metaInfo() {
        return {
          title: "Sem Permissão",
          titleTemplate: "%s - Casa Civil do Estado de Goiás",
        };
      },
  };
</script>

<style lang="scss" scoped>
</style>