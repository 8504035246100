<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Onus" />
            <h1>{{ onusForm.id ? "Editar" : "Novo" }} Ônus</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>               
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="flexbox-2">
                            <div class="item">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="onusForm.nome" />
                            </div>
                            <div class="item">
                                <div class="radio">
                                    <label class="radio-label"><b>{{ possuiAsterisco("Ativo:") }}</b></label>
                                    <div class="radio-buttons">
                                        <div class="radio-item">
                                            <label for="ativo-onus">Sim</label>
                                            <input type="radio" id="ativo-onus"
                                                :value=true v-model="onusForm.ativo">
                                        </div>                                                
                                        <div class="radio-item">
                                            <label for="ativo-onus">Não</label>
                                            <input type="radio" id="ativo-onus"
                                                :value=false v-model="onusForm.ativo">
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>                     
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ onusForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import OnusApi from "../../services/OnusApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";

    export default {
        name: "OnusForm",
        props: {
            onus: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                count: -1,
                count1: null,
                filess: null,
                carregando: true,
                erro: false,
                msgErros: {
                    nome: '',
                    ativo: '',
                },
                onusForm: this.onus
                    ? {
                        id: this.onus.id,
                        nome: this.onus.nome,
                        ativo: this.onus.ativo
                    }
                    : {
                        id: null,
                        nome: null,
                        ativo: null,
                    },
            };
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
        },
        created() {
            this.onusApi = new OnusApi();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },

            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;
                
                let flag = this.validarForm(this.onusForm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.onusApi.cancelTokens.setOnus) {
                        this.onusApi.cancelTokens.setOnus.cancel();
                    }

                    let data;
                    data = await this.onusApi.setOnus(
                        this.onusForm
                    );

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "OnusShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                    //window.location.reload();
                } catch (err) {
                    if (this.onusApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            validarForm(onusForm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros.nome = ''
                this.msgErros.ativo = ''
                this.msgErros.default = ''

                if(onusForm.nome == null || onusForm.nome == ''){
                    this.msgErros.nome = "O campo Nome não foi preenchido.";
                    flag = 1;
                }
                
                if(onusForm.ativo == null){
                    this.msgErros.ativo = "O campo Ativo não foi preenchido.";
                    flag = 1;
                } 

                return flag;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
            
        },

    };
</script>

<style lang="scss">
    @media screen and (min-width: "1066px") {
        .caixa-form {
            .flexbox-1 {
                display: grid;
                grid-template-columns: 1fr .5fr 2fr;
                gap: 2rem;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 3fr 1.5fr;
                gap: 1.5rem;
            }
        }
    }
</style>
