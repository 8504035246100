<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <div v-if="erro">
                <SemPermissao></SemPermissao>
            </div>
            <template v-if="!erro">
                <ExibirStatus :status="status" :temPermissao="temPermissao"/>
            </template>
        </div>
    </div>
</template>

<script>
    import StatusApi from "../../services/StatusApi";
    import SemPermissao from "../SemPermissao.vue"
    
    //import ErroResultados from "../../components/ErroResultados";
    import ExibirStatus from "../../components/status/ExibirStatus.vue";
    import metaDados from "../../helpers/metaDados";
    import Auth from '../../services/Auth';

    export default {
        name: "StatusShow",
        metaInfo() {
            if (!this.status) {
                return {};
            }

            let title = this.status.nome;
            const description = this.status.nome;

            return {
                title,
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "description",
                        name: "description",
                        property: "description",
                        content: description,
                    },
                    {
                        vmid: "og:description",
                        property: "og:description",
                        content: description,
                    },
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    {
                        vmid: "twitter:description",
                        name: "twitter:description",
                        property: "twitter:description",
                        content: description,
                    },
                    {
                        vmid: "twitter:title",
                        name: "twitter:title",
                        property: "twitter:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                temPermissao: false,
                carregando: true,
                erro: false,
                status: null,
            };
        },
        components: {
            ////ErroResultados,
            SemPermissao,
            ExibirStatus,
        },
        created() {
            this.StatusApi = new StatusApi();
            let auth = new Auth();
            this.carregarStatus();
            this.temPermissao = auth.temPermissao;
        },
        watch: {
            $route: function (to, from) {
                if (to.params.id != from.params.id) {
                    this.carregarStatus();
                }
            },
        },
        methods: {
            carregarStatus: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.StatusApi.cancelTokens.getStatus) {
                        this.StatusApi.cancelTokens.getStatus.cancel();
                    }

                    const dadosStatus = await this.StatusApi.getStatus(
                        this.$route.params.id
                    );
                    this.status = dadosStatus

                    this.carregando = false;
                } catch (err) {
                    if (this.StatusApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);

                    this.erro = true;
                    this.carregando = false;
                    this.status = null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>