<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <div v-if="erro">
                <SemPermissao></SemPermissao>
            </div>
            <template v-if="!erro">
                <ExibirCessao :cessao="cessao" :temPermissao="temPermissao"/>
            </template>
        </div>
    </div>
</template>

<script>
    import CessaoApi from "../../services/CessaoApi";
    import SemPermissao from "../SemPermissao.vue"
    //import ErroResultados from "../../components/ErroResultados";
    import ExibirCessao from "../../components/cessoes/ExibirCessao.vue";
    import metaDados from "../../helpers/metaDados";
    import Auth from '../../services/Auth';

    export default {
        name: "CessaoShow",
        metaInfo() {
            if (!this.cessao) {
                return {};
            }

            let title = this.cessao.nome;
            const description = this.cessao.nome;

            return {
                title,
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "description",
                        name: "description",
                        property: "description",
                        content: description,
                    },
                    {
                        vmid: "og:description",
                        property: "og:description",
                        content: description,
                    },
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    {
                        vmid: "twitter:description",
                        name: "twitter:description",
                        property: "twitter:description",
                        content: description,
                    },
                    {
                        vmid: "twitter:title",
                        name: "twitter:title",
                        property: "twitter:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                temPermissao: null,
                carregando: true,
                erro: false,
                cessao: null,
            };
        },
        components: {
            ////ErroResultados,
            SemPermissao,
            ExibirCessao,
        },
        created() {
            this.cessaoApi = new CessaoApi();
            let auth = new Auth();
            this.carregarCessao();
            this.temPermissao = auth.temPermissao;
        },
        watch: {
            $route: function (to, from) {
                if (to.params.id != from.params.id) {
                    this.carregarCessao();
                }
            },
        },
        methods: {
            carregarCessao: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.cessaoApi.cancelTokens.getCessao) {
                        this.cessaoApi.cancelTokens.getCessao.cancel();
                    }

                    this.cessao = await this.cessaoApi.getCessao(
                        this.$route.params.id
                    );

                    this.carregando = false;
                } catch (err) {
                    if (this.cessaoApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);

                    this.erro = true;
                    this.carregando = false;
                    this.cessao = null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>