<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>CPF</th>
          <th>Nome</th>
          <th>Quantidade de cargos</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="servidor in servidoresFormat">
          <template>
            <tr :key="servidor.id">
              <td data-label="CPF:" class="topo-card">
                <span>{{ formatarCPF(servidor.cpf) }}</span>
              </td>
              <td data-label="Nome:">
                <span>{{ servidor.nome }}</span>
              </td>
              <td data-label="Quantidade de cargos:">
                <span>{{ servidor.quantidade_cargos }}</span>
              </td>
              <td class="coluna-acoes-tabela">
                <!-- Visualizar -->
                <div class="icone-tabela">
                  <router-link :to="{
                    name: 'ServidorShow',
                    params: { id: servidor.id },
                  }">
                    <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                  </router-link>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <tr v-if="!servidores || servidores == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"
  import MsgErroFormularios from "../MsgErroFormularios";
  import ServidorApi from "../../services/ServidorApi";
  import CargoServidorApi from "../../services/CargoServidorApi";

  export default {
    name: "TabelaResultadosServidores",
    props: {
      servidores: Array,
      servidor: Object,
    },
    components: {
      MsgErroFormularios,
    },
    created() {
      this.servidorApi = new ServidorApi();
      this.cargoServidorApi = new CargoServidorApi();
    },
    data() {
      return {
        msgErros: {
          servidor: '',
        },
        erro: false,
        servidoresFormat: this.servidores.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
      formatarCPF(cpf) {
        // Lógica para formatar o CPF (exemplo: 123.456.789-01)
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            min-width: 10rem;
            text-align-last: center;

            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }
    }
  }
</style>