<template>
  <div class="footer">
    <div class="criado-por">
      <p>{{ createdByLabel }}: </p>
      <span v-if="criadoPor">{{ criadoPor }}</span>
      <span v-else>{{ SistemaLabel }}</span>
      <p>{{ emLabel }}</p>
      <div class="inline" v-if="created_at != null">
        {{ formatarDataHora(created_at) }}
      </div>
      <div class="inline" v-else>
        -
      </div>
    </div>
    <div class="atualizado-por">
      <p>{{ lastUpdatedByLabel }}: </p>
      <span v-if="atualizadoPor">{{ atualizadoPor }}</span>
      <span v-else>{{ SistemaLabel }}</span>
      <p>{{ emLabel }}</p>
      <div class="inline" v-if="updated_at != null">
        {{ formatarDataHora(updated_at) }}
      </div>
      <div class="inline" v-else>
        -
      </div>
    </div>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"

  export default {
    props: {
      createdByLabel: String,
      criadoPor: String,
      lastUpdatedByLabel: String,
      atualizadoPor: String,
      emLabel: String,
      SistemaLabel: String,
      created_at: String,
      updated_at: String
    },
    methods: {
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .footer {
    display: block;
    width: fit-content;
    height: 2rem;
    color: #000000;
    margin: 0 0 0 3rem;

    .criado-por {
      display: block;
      font-size: .9rem;
      /* Alterado as seguintes fontes para padronizar o rodapé de informações */
      font-family: "Montserrat-Medium";

      p {
        display: inline;
        /* font-weight: bold; */
        font-size: .9rem;
        font-family: "Montserrat-bold";
      }
    }

    .atualizado-por {
      margin: 1rem 0 0 0;
      display: block;
      font-size: .9rem;
      font-family: "Montserrat-Medium";

      p {
        display: inline;
        /* font-weight: bold; */
        font-size: .9rem;
        font-family: "Montserrat-bold";
      }
    }
    .inline{
      display: inline-block;
    }
  }
</style>