<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>UF</th>
          <th>Sigla</th>
          <th>Esfera</th>
          <th>Poder</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="orgao in orgaosFormat" :key="orgao.id">
          <td data-label="Nome:" class="topo-card">
            <span>{{ orgao.nome }}</span>
          </td>
          <td data-label="UF:">
            <span>{{ orgao.uf }}</span>
          </td>
          <td data-label="Sigla:">
            <span v-if="orgao.sigla !== null">{{ orgao.sigla }}</span>
            <span v-else>-</span>
          </td>
          <td data-label="Esfera:">
            <span>{{ orgao.esfera }}</span>
          </td>
          <td data-label="Poder:">
            <span>{{ orgao.poder }}</span>
          </td>
          <td class="coluna-acoes-tabela">
            <!-- Visualizar -->
            <div class="icone-tabela">
              <router-link
                :to="{
                  name: 'OrgaoShow',
                  params: { id: orgao.id },
                }"
              >
                <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar"/>
              </router-link>
            </div>
            <!-- Editar -->
            <div class="icone-tabela">
              <router-link
                :to="{
                  name: 'OrgaoEdit',
                  params: { id: orgao.id },
                }"
              >
                <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar"/>
              </router-link>
            </div>
            <!-- Excluir -->
            <span class="icone-tabela" type="button" @click="opcoesExcluir(orgao.id)">
              <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir"/>
            </span>  
          </td>
          <div class="modal-exclusao" :id="orgao.id">
            <div class="modal-mask">
              <div class="modal-container">
                <div class="modal-header">
                  <h2>Deseja mesmo excluir esse Órgão?</h2>
                </div>
                <div class="modal-body">
                  <p>Dados do Órgão:</p>
                  <div class="dados">
                    <div class="texto-centralizado">
                      Nome: <span>{{ orgao.nome }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Sigla: <span>{{ orgao.sigla }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Esfera: <span>{{ orgao.esfera }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Poder: <span>{{ orgao.poder }}</span>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn-confirmar-exclusao" @click="excluirLinha(orgao.id)">Sim</button>
                  <button class="btn-cancelar-exclusao" @click="opcoesExcluir(orgao.id)">Não</button>
                </div>
              </div>
            </div>
          </div>
        </tr>
        <tr v-if="!orgaos || orgaos == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {parseISO, format} from "date-fns"

  import MsgErroFormularios from "../MsgErroFormularios";
  import OrgaoApi from "../../services/OrgaoApi"

  export default {
    name: "TabelaResultadosOrgaos",
    props: {
      orgaos: Array,
      orgao: Object,
    },
    created() {
      this.orgaoApi = new OrgaoApi();
    },
    components: {
      MsgErroFormularios,
    },
    data() {
      return {
        msgErros: {
          orgao: '',
          default: '',
        },
        erro: false,
        orgaosFormat: this.orgaos.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      opcoesExcluir(orgaoId) {
        let modalExclusao = document.getElementsByClassName('modal-exclusao')

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == orgaoId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible"
            } else {
              modalExclusao[i].style.visibility = "hidden"
            }
          }
        }
      },
      async excluirLinha(orgaoId) {
        this.opcoesExcluir(orgaoId)

        try {
          this.carregando = true;
          this.erro = false;

          if (this.orgaoApi.cancelTokens.destroyOrgao) {
            this.orgaoApi.cancelTokens.destroyOrgao.cancel();
          }

          await this.orgaoApi.destroyOrgao(orgaoId);
          window.location.reload();

        } catch (err) {
          if (this.orgaoApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;

          this.msgErros.orgao = "Não foi possível excluir o órgão, pois ele está vinculado a uma cessão/disposição.";
        }
      },
      formatarData: function(value) {
        if(value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>