<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <ConvenioCotaForm :convenioCota="convenioCota"/>
        </div>
    </div>
</template>

<script>
    import ConvenioCotaApi from '../../services/ConvenioCotaApi';
    import ConvenioCotaForm from '../../components/convenios_cotas/ConvenioCotaForm.vue';

    export default {
        name: "ConveniosCotasEdit",
        data() {
            return {
                carregando: true,
                erro: false,
                convenioCota: null,
            };
        },
        components: {
            ConvenioCotaForm,
        },
        created() {
            this.convenioCotaApi = new ConvenioCotaApi();
            this.carregarConvenioCota();
        },
        watch: {
            $route: function (to, from) {
                if (to.params.id != from.params.id) {
                    this.carregarConvenioCota();
                }
            },
        },
        methods: {
            carregarConvenioCota: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.convenioCotaApi.cancelTokens.getConvenioCota) {
                        this.convenioCotaApi.cancelTokens.getConvenioCota.cancel();
                    }

                    const dadosConvenioCota = await this.convenioCotaApi.getConvenioCota(
                        this.$route.params.id
                    );
                    this.convenioCota = dadosConvenioCota

                    this.carregando = false;
                } catch (err) {
                    if (this.convenioCotaApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);

                    this.erro = true;
                    this.carregando = false;
                    this.convenioCota = null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>