<template>
  <div class="fora">
    <div class="topo-header centro">
      <botao-voltar class="voltar" nomeRota="Status" />
      <h1>Visualizar Status</h1>
    </div>
    <div class="form centro">
      <div class="dados">
        <fieldset>
          <div class="dados">
            <div class="flexbox-1">
              <p>Nome:
                <span>{{ status.nome }}</span>
              </p>
              <p>Código:
                <span>{{ status.codigo }}</span>
              </p>
              <p>Ativo:
                <span v-if="status.ativo == true">Sim</span>
                <span v-else>Não</span>
              </p>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
        <div class="div-center">
          <div :id="status.id">
            <a :href="'/status/' + status.id + '/edit'">
              <button id="botao-editar">
                Editar
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer
        :createdByLabel="'Criado por'"
        :criadoPor="status.criado_por"
        :lastUpdatedByLabel="'Última atualização feita por'"
        :atualizadoPor="status.atualizado_por"
        :emLabel="' em '"
        :SistemaLabel="'Sistema'"
        :created_at="status.created_at"
        :updated_at="status.updated_at"
      />
    </div>
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import StatusApi from "../../services/StatusApi";
  import { parseISO, format } from "date-fns"
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirStatus",
    props: {
      status: Object,
      temPermissao: Object,
    },
    data() {
      return {
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        hasError: false,
        r: false,
        
        dadosStatus: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      }
    },
    created() {
      this.StatusApi = new StatusApi();
    },
    components: {
      BotaoVoltar,
      Footer,
    },
    methods: {
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 1066px) {
    .fora {
      .form {
        fieldset {
          .dados {
            .flexbox-1 {
              display: grid;
              grid-template-columns: 1.5fr 1fr;
              gap: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1065px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;
    }
  }

  @media screen and (min-width: 80px) and (max-width: 599px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;
    }
  }
</style>