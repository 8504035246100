<template>
  <div class="botao">
    <button @click="voltar()">
      <i class="fas fa-arrow-left"/>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      nomeRota: {
        type: String
      }
    },
    methods: {
      voltar() {
        if (this.$router.pagina_anterior == "CessaoEdit" ||
            this.$router.pagina_anterior == "DisposicaoEdit" ||
            this.$router.pagina_anterior == "ServidorEdit" ||
            this.$router.pagina_anterior == "ConveniosCotasEdit" ||
            this.$router.pagina_anterior == "OrgaoEdit" ||
            this.$router.pagina_anterior == "OnusEdit" ||
            this.$router.pagina_anterior == "StatusEdit" ||
            this.$router.pagina_anterior == "CessaoNew" ||
            this.$router.pagina_anterior == "DisposicaoNew" ||
            this.$router.pagina_anterior == "ServidorNew" ||
            this.$router.pagina_anterior == "ConveniosCotasNew" ||
            this.$router.pagina_anterior == "OrgaoNew" ||
            this.$router.pagina_anterior == "OnusNew" ||
            this.$router.pagina_anterior == "StatusNew"
        ) {
          window.history.back(-1);
          window.history.back(-1);
        } else {
          window.history.back();
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .botao {
    button{
      border-radius: 1rem;
      border: none;
      background-color: #f8f8f8;
    }
    button:hover{
      transition: 0.4s;
      background-color: #125b19;
      padding: 0.3rem 0.3rem 0 0.3rem;
      .fa-arrow-left {
        transition: 0.4s;
        color: white;
      }
    }
    .fa-arrow-left {
      cursor: pointer;
      color: #125b19;
      height: 1.5rem;
      width: 1.3rem;
    }
  }
</style>
