<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Servidor" />
            <h1>{{ servidorForm.id ? "Editar" : "Novo(a)" }} Servidor(a)</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>                
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="flexbox-1">
                            <div class="item" v-if="servidorForm.id">
                                <label><b>{{ possuiAsterisco("CPF:") }}</b></label>
                                <the-mask :mask="['###.###.###-##']" placeholder="Digite o CPF" v-model="servidorForm.cpf" disabled/>
                            </div>
                            <div class="item" v-else>
                                <label><b>{{ possuiAsterisco("CPF:") }}</b></label>
                                <the-mask id="input-cpf" :mask="['###.###.###-##']" placeholder="Digite o CPF" v-model="servidorForm.cpf"/>
                            </div>
                            <div class="item" v-if="servidorForm.id">
                                <button id="btn-carregar-dados" class="btn-carregar-dados" @click.prevent="buscarDados(servidorForm.cpf)" disabled>
                                    <img id="spinner-loading-carregar" class="spinner-loading"
                                        src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                    <p id="conteudo-botao-carregar">Carregar Dados</p>
                                </button>
                            </div>
                            <div class="item" v-else>
                                <button id="btn-carregar-dados" class="btn-carregar-dados" @click.prevent="buscarDados(servidorForm.cpf)">
                                    <img id="spinner-loading-carregar" class="spinner-loading"
                                        src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                    <p id="conteudo-botao-carregar">Carregar Dados</p>
                                </button>
                            </div>
                            <div class="item" v-if="servidorForm.id">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="servidorForm.nome" disabled />
                            </div>
                            <div class="item" v-else>
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input id="input-nome" type="text" placeholder="Digite o nome" v-model="servidorForm.nome" />
                            </div>
                        </div>
                        <div class="item">
                            <div id="divCargos">
                                <div class="item multiselect">
                                    <MultiplosCargos v-model="servidorFormm.cargos_attributes"></MultiplosCargos>
                                </div>
                            </div>
                        </div>
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ servidorForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import ServidorApi from "../../services/ServidorApi";
    import CargoApi from "../../services/CargoApi";
    import CargoServidorApi from "../../services/CargoServidorApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";
    import MultiplosCargos from '../../components/servidores/MultiplosCargos.vue';

    export default {
        name: "ServidorForm",
        props: {
            servidor: {
                type: Object,
                required: false,
            },
            cargos: {
                type: Object,
                required: false,
            },
            cargosServidorForm: {
                type: Array,
                required: false,
            },
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
            MultiplosCargos
        },
        data() {
            return {
                nomeRotaAtual: this.$router.currentRoute.name,
                carregando: true,
                erro: false,
                msgErros: {
                    cpf: '',
                    nome: '',
                    cargo: '',
                    default: '',
                },
                dadosRhNet: null,
                cargoServidor: [],
                cargosCadastrados: [],
                servidorForm: this.servidor
                    ? {
                        id: this.servidor.id,
                        cpf: this.servidor.cpf,
                        nome: this.servidor.nome,
                    }
                    : {
                        id: null,
                        cpf: null,
                        nome: null,
                    }, 
                servidorFormm: this.servidor
                    ? {
                        cargos_attributes: this.servidor.cargos
                            ? this.servidor.cargos
                            : [],
                    }
                    : {
                        cargos_attributes: [],
                    },        
            };
        },
        created() {
            this.servidorApi = new ServidorApi();
            this.cargoApi = new CargoApi();
            this.cargoServidorApi = new CargoServidorApi();
            this.carregarCargos();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            async carregarCargos() {
                try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosCadastrados = await this.servidorApi.getCargos();
                this.cargosCadastrados = dadosCadastrados

                } catch (err) {
                console.log("carregarCargos", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.cargosCadastrados = [];
                }
            },
            async buscarDados(cpf) {
                let conteudoBotao = document.getElementById("conteudo-botao-carregar");
                const textoConteudoBotao = conteudoBotao.innerHTML;
                try {
                    this.carregando = true;
                    this.erro = false;

                    this.mudarBotaoCarregar(textoConteudoBotao);

                    const rhNet = await this.servidorApi.getDadosRhNet(cpf);
                    this.dadosRhNet = rhNet
                    this.servidorForm.nome = this.dadosRhNet.nome

                    const cargoCarregado = {
                        id: null,
                        nome: this.dadosRhNet.cargo
                    }

                    this.cargosCadastrados.map((cargoCadastrado) => {
                        if(cargoCarregado.nome == cargoCadastrado.nome) {
                            cargoCarregado.id = cargoCadastrado.id;
                        }
                    }) 

                    this.servidorFormm.cargos_attributes.push(cargoCarregado)

                    this.mudarBotaoCarregarDesabilitado(textoConteudoBotao);
                } catch (err) {
                    if (this.servidorApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoCarregar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data.mensagem;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },
            mudarBotaoCarregar(textoBotao) {
                let botaoCarregar = document.getElementById("btn-carregar-dados");
                let conteudoBotao = document.getElementById("conteudo-botao-carregar");
                let spinnerLoading = document.getElementById("spinner-loading-carregar");

                if(botaoCarregar.disabled == true) {
                    botaoCarregar.disabled = false;
                    botaoCarregar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                } else {
                    let novoTextoConteudoBotao = "Carregando"

                    //mudar para o carregando
                    botaoCarregar.disabled = true;
                    botaoCarregar.style.backgroundColor = "gray"
                    conteudoBotao.innerHTML = novoTextoConteudoBotao;
                    conteudoBotao.style.display = "inline-block"
                    spinnerLoading.style.visibility = "visible"
                    spinnerLoading.style.position = "relative"
                }

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoCarregar.disabled = false;
                    botaoCarregar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },
            mudarBotaoCarregarDesabilitado(textoBotao) {
                let botaoCarregar = document.getElementById("btn-carregar-dados");
                let inputCpf = document.getElementById("input-cpf");
                let inputNome = document.getElementById("input-nome");
                let conteudoBotao = document.getElementById("conteudo-botao-carregar");
                let spinnerLoading = document.getElementById("spinner-loading-carregar");

                //mudar para o carregando
                botaoCarregar.disabled = true;
                inputCpf.disabled = true;
                inputNome.disabled = true;
                botaoCarregar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = textoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "hidden"
                spinnerLoading.style.position = "absolute"
            },
            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;

                let flag = this.validarForm(this.servidorForm, this.servidorFormm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    this.msgErros = '';

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.servidorApi.cancelTokens.setServidor) {
                        this.servidorApi.cancelTokens.setServidor.cancel();
                    }

                    let dataServidor;
                    if(this.nomeRotaAtual == "ServidorNew") {
                        dataServidor = await this.servidorApi.setServidor(
                            this.servidorForm
                        );

                        this.servidorFormm.cargos_attributes.map(async (cargo) => {
                            if (this.cargoApi.cancelTokens.setCargo) {
                                this.cargoApi.cancelTokens.setCargo.cancel();
                            }

                            let dataCargo;
                            dataCargo = await this.cargoApi.setCargo(
                                cargo
                            );

                            this.cargoServidor = {
                                id: null,
                                cargo_id: dataCargo.id,
                                servidor_id: dataServidor.id
                            }

                            await this.cargoServidorApi.setCargoServidor(
                                this.cargoServidor,
                            );
                        })
                    } else {
                        dataServidor = await this.servidorApi.setServidor(this.servidorForm);

                        for(const cargo of this.servidorFormm.cargos_attributes) {
                            if (this.cargoApi.cancelTokens.setCargo) {
                                this.cargoApi.cancelTokens.setCargo.cancel();
                            }

                            let dataCargo;
                            dataCargo = await this.cargoApi.setCargo(
                                cargo
                            );

                            this.cargoServidor = {
                                id: null,
                                cargo_id: dataCargo.id, 
                                servidor_id: dataServidor.id 
                            }

                            let exists = this.cargosServidorForm.some(
                                (cargoServidorForm) => cargoServidorForm.cargo_id === this.cargoServidor.cargo_id
                            );

                            if(!exists) {
                                this.cargosServidorForm.push(this.cargoServidor)
                            }
                        }

                        for(const cargoServidorForm of this.cargosServidorForm) {
                            let exists2 = this.servidorFormm.cargos_attributes.some(
                                (cargo) => cargo.id === cargoServidorForm.cargo_id
                            );

                            if (!exists2) {
                                await this.cargoServidorApi.destroyCargosServidores(cargoServidorForm.cargo_id, cargoServidorForm.servidor_id);
                                this.cargosServidorForm.splice(this.cargosServidorForm.indexOf(cargoServidorForm), 1);
                            }
                        }

                        for(const cargoServidorForm of this.cargosServidorForm) {
                            await this.cargoServidorApi.setCargoServidor(cargoServidorForm);
                        }
                    }

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "ServidorShow",
                            params: {
                                id: dataServidor.id,
                            },
                        });
                    }, 1000)
                } catch (err) {
                    if (this.servidorApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },           
            validarForm(servidorForm, servidorFormm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros = {
                    cpf: "",
                    nome: "",
                    cargo: "",
                    default: "",
                }

                //CPF não pode estar em branco
                if (servidorForm.cpf == null || servidorForm.cpf == '') {
                    this.msgErros.cpf = "O campo CPF não foi preenchido.";
                    flag = 1;
                }
                
                //CPF tem que ser válido
                if(servidorForm.cpf) {
                    if(!this.validarCPF(servidorForm.cpf)){
                        this.msgErros.cpf = "CPF inválido.";
                        flag = 1;
                    }
                }

                //Nome não pode estar em branco
                if (servidorForm.nome == null || servidorForm.nome == '') {
                    this.msgErros.nome = "O campo nome não foi preenchido.";
                    flag = 1;
                }

                if(servidorFormm.cargos_attributes.length == 0) {
                    this.msgErros.cargo = "Nenhum cargo adicionado ao servidor. Se o servidor não tiver cargo, adicionar o cargo 'Nenhum cargo encontrado' ";
                    flag = 1;
                }
                
                if(servidorFormm.cargos_attributes.length > 0) {
                    servidorFormm.cargos_attributes.map((cargo) => {
                        //Cargos não pode estar em branco
                        if (cargo.nome == null || cargo.nome == '') {
                            this.msgErros.cargo = "O campo nome do cargo não foi preenchido.";
                            flag = 1;
                        }
                    })
                }

                return flag;
            },
            validarCPF(cpf) {
                cpf = cpf.replace(/\D/g, '');
                if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
                    return false;
                }

                let soma = 0;
                let resto;

                for (let i = 1; i <= 9; i++) {
                    soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
                }
                resto = (soma * 10) % 11;

                if (resto === 10 || resto === 11) resto = 0;
                if (resto !== parseInt(cpf.charAt(9))) {
                    return false;
                }

                soma = 0;
                for (let i = 1; i <= 10; i++) {
                    soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
                }
                resto = (soma * 10) % 11;

                if (resto === 10 || resto === 11) resto = 0;
                if (resto !== parseInt(cpf.charAt(10))) {
                    return false;
                }

                return true;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
        },

    };
</script>

<style lang="scss">

    div#divCargos {
        place-self: center;
        width: 100%;
    }

    @media screen and (min-width: "1066px") {
        .caixa-form {
            .flexbox-1 {
                display: grid;
                grid-template-columns: 1fr .5fr 2fr;
                gap: 2rem;
            }        

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 3fr 1.5fr;
                gap: 1.5rem;
            }
        }
    }
</style>
