<template>
    <div>
        <div v-if="erro">
            <SemPermissao></SemPermissao>
        </div>
        <template v-if="!erro">
            <OrgaoForm :orgao="null" />
        </template>
    </div>
</template>

<script>
    import OrgaoForm from '../../components/orgaos/OrgaoForm.vue';
    import SemPermissao from "../SemPermissao.vue"
    
    //import ErroResultados from "../../components/ErroResultados";

    export default {
        name: "OrgaoNew",
        data() {
            return {
                erro: false,
            };
        },
        components: {
            SemPermissao,
            //ErroResultados,
            OrgaoForm,
        },
    };
</script>

<style lang="scss" scoped>
</style>