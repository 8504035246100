<template>
  <div class="div-conteudo">
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="index centro">
        <div class="topo-index">
          <h1>Órgãos</h1>
          <router-link v-if="temPermissao.admin" :to="{
            name: 'OrgaoNew',
          }">
            <span>+</span> Novo Órgão
          </router-link>
        </div>
        <div class="caixa-pesquisa"> <!-- MUDAR ITENS DA PESQUISA -->
          <h2 @click="tooglePesquisa()">
            Pesquisa
            <span>
              <template v-if="!exibirPesquisa">&#9650;</template>
              <template v-else>&#9660;</template>
            </span>
          </h2>
          <transition name="slide">
            <form @submit.prevent="fazerBusca" v-if="!exibirPesquisa">
              <div>
                <div class = "row3-data">
                  <div class="item">
                    <label> Nome: </label>
                    <input type="text" placeholder="Digite o nome" v-model="filtro.nome" />
                  </div>
                  <div class="item">
                    <label> Sigla: </label>
                    <input type="text" placeholder="Digite a sigla" v-model="filtro.sigla" />
                  </div>
                  <div class="item">
                      <!--{{ rodarOptions() }}-->
                      <label for="selectUf">UF:</label>
                      <v-select 
                        v-model="filtro.ufs_id" 
                        id="selectUf" 
                        :options="optionsUfs" 
                        :reduce="uf => uf.id"
                        label="uf" 
                        placeholder="Todos">
                      </v-select>
                  </div>
                </div>
                <div class="row2-data">
                  <div class="item">
                    <!--{{ rodarOptions() }}-->
                    <label for="selectEsfera">Esfera:</label>
                    <v-select 
                      v-model="filtro.esfera" 
                      id="selectEsfera" 
                      :options="optionsEsferas" 
                      :reduce="nome => nome.id"
                      label="nome" 
                      placeholder="Todos">
                    </v-select>
                  </div>
                  <div class="item">
                      <!--{{ rodarOptions() }}-->
                      <label for="selectPoder">Poder:</label>
                      <v-select 
                        v-model="filtro.poder" 
                        id="selectPoder" 
                        :options="optionsPoderes" 
                        :reduce="nome => nome.id"
                        label="nome" 
                        placeholder="Todos">
                      </v-select>
                    </div>
                </div>
              </div>
              <div class="botoes-pesquisa">
                <div class="bt-pesquisa">
                  <button title="Pesquisar" type="submit">
                    <img src="../../assets/imagens/pesquisa.png" class="pesquisa" alt=" " />
                    Pesquisar
                  </button>
                </div>
                <div class="bt-limpar">
                  <button title="Limpar" @click="limparFiltro()">
                    <img src="../../assets/imagens/icon-eraser.png" class="limpar" alt=" " />
                    Limpar Filtros
                  </button>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="n-div">
          <div class="nresultados" v-show="!carregando">
            <label>Total de Resultados: {{ this.totalResultadosTexto }}</label>
          </div>
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página </label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div v-if="temPermissao.admin || temPermissao.gpd" class="container-tabela">
          <TabelaResultadosOrgaos :orgaos="orgaos" :orgao="orgao"
            v-if="!carregando && !erro && !erroFiltro" @excluir-linha="excluirLinha">
          </TabelaResultadosOrgaos>
        </div>
        <div v-else class="container-tabela">
          <TabelaResultadosOrgaosUsuario :orgaos="orgaos" :orgao="orgao"
            v-if="!carregando && !erro && !erroFiltro" @excluir-linha="excluirLinha">
          </TabelaResultadosOrgaosUsuario>
        </div>
        <paginate v-if="!carregandoFiltro && !carregando" v-model="pagina" :page-count="pageCount"
              :click-handler="mudouPagina" :prev-text="'Anterior'" :next-text="'Próxima'" :container-class="'className'"
              active-class="active-page"></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import Paginate from "vuejs-paginate";
  import tryParseInt from "../../helpers/tryParseInt";
  import metaDados from "../../helpers/metaDados";
  import TabelaResultadosOrgaos from "../../components/orgaos/TabelaResultadosOrgaos.vue";
  import TabelaResultadosOrgaosUsuario from "../../components/orgaos/TabelaResultadosOrgaosUsuario.vue";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import OrgaoApi from "../../services/OrgaoApi";
  import Auth from '../../services/Auth';

  export default {
    name: "Orgao",
    metaInfo() {
      return {
        title: "Órgãos",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa de todos os órgãos realizados.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa de todos os órgãos realizados.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Órgãos - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        temPermissao: null,
        orgaos: [],
        orgao: null,
        esferas: [],
        poderes: [],
        ufs: [],
        optionsEsferas: [],
        optionsPoderes: [],
        optionsUfs: [],
        carregando: true,
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        filtro: {
          nome: null,
          sigla: null,
          esfera: null,
          poder: null,
          ufs_id: null,
        },
        exibirPesquisa: true,
        carregandoFiltro: false,
        erroFiltro: false,
      };
    },
    components: {
      TabelaResultadosOrgaos,
      TabelaResultadosOrgaosUsuario,
      Paginate,
      vSelect
    },
    created() {
      this.orgaoApi = new OrgaoApi();
      let auth = new Auth();
      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarOrgao();
      this.temPermissao = auth.temPermissao;
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.nome != from.query.nome ||
          to.query.sigla != from.query.sigla ||
          to.query.esfera != from.query.esfera ||
          to.query.poder != from.query.poder || 
          to.query.ufs_id != from.query.ufs_id;
        if (
          from.name == "Orgao" &&
          to.name == "Orgao" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarOrgao();
        }
      },
    },
    methods: {
      setarOptions() {
        const POSICAO_ZERO = 0;
        for (let i = 0; i < this.esferas.length; i++) {
          this.optionsEsferas[i] = {
            id: i,
            nome: this.esferas[i][POSICAO_ZERO]
          }
        }
        for (let i = 0; i < this.poderes.length; i++) {
          this.optionsPoderes[i] = {
            id: i,
            nome: this.poderes[i][POSICAO_ZERO]
          }
        }
        for (let i = 0; i < this.ufs.length; i++) {
          this.optionsUfs[i] = this.ufs[i]
        }
      },
      tooglePesquisa: function () {
        this.exibirPesquisa = !this.exibirPesquisa;
      },
      async excluirLinha(orgaoId) {
        try {
          this.carregando = true; 
          this.erro = false;

          if (this.orgaoApi.cancelTokens.destroyOrgao) {
            this.orgaoApi.cancelTokens.destroyOrgao.cancel();
          }

          await this.orgaoApi.destroyOrgao(orgaoId);

          this.carregarOrgao();
          this.carregarQtdPorPagina();
          this.carregarValoresFiltro();

        } catch (err) {
          if (this.orgaoApi.isCancel(err)) {
            return;
          }
          console.log("excluirOrgao", err);

          this.carregando = false;
          this.erro = true;
        }
      },
      fazerBusca() {
        this.$router.push({
          name: "Orgao",
          query: {
            ...this.filtro,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      mudouPagina: function (page) {
        this.pagina = page;

        const filtro = {
          nome: this.getParam("nome"),
          sigla: this.getParam("sigla"),
          esfera: this.getParam("esfera"),
          poder: this.getParam("poder"),
          ufs_id: this.getParam("ufs_id"),
        };

        this.$router.push({
          name: "Orgao",
          query: {
            ...filtro,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      carregarValoresFiltro: async function () {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false

          const dadosEsferas = await this.orgaoApi.getEsferas();
          this.esferas = dadosEsferas

          const dadosPoderes = await this.orgaoApi.getPoderes();
          this.poderes = dadosPoderes

          const dadosUfs = await this.orgaoApi.getUfs();
          this.ufs = dadosUfs
          
          this.setarOptions();
        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.esferas = [];
          this.poderes = [];
          this.ufs = [];
        }
      },
      carregarOrgao: async function () {
      try {
        this.carregando = true;
        this.erro = false;

        this.qtd_por_pagina = this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

        this.armazenarQtdPorPagina();

        const pagina = this.getParam("pagina") || 1;
        this.pagina = parseInt(pagina);

        this.filtro = {
          nome: this.getParam("nome"),
          sigla: this.getParam("sigla"),
          esfera: this.getParam("esfera"),
          poder: this.getParam("poder"),
          ufs_id: this.getParam("ufs_id"),
        };

        if (this.orgaoApi.cancelTokens.getOrgaos) {
          this.orgaoApi.cancelTokens.getOrgaos.cancel();
        }

        let data = await this.orgaoApi.getOrgaos({
          ...this.filtro,
          qtd_por_pagina: this.qtd_por_pagina,
          page: this.pagina,
        });

        this.orgaos = data.resultados;
        this.totalResultados = data.total_resultados;
        if (data.total_resultados >= 1000) {
          this.totalResultadosTexto = (data.total_resultados / 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        } else {
          this.totalResultadosTexto = data.total_resultados;
        }

        this.carregando = false;
      } catch (err) {
        if (this.orgaoApi.isCancel(err)) {
          return;
        }
        console.log("carregarOrgao", err);
        this.carregando = false;
        this.erro = true;
      }
    },
      limparFiltro: function () {
        this.tooglePesquisa();
        this.filtro = {
          nome: undefined,
          sigla: undefined,
          esfera: undefined,
          poder: undefined,
          ufs_id: undefined,
        };
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_orgao",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_orgao");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>