<template>
    <div class="div-conteudo">
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div class="fora" v-else>
            <div class="index centro">
                <div class="topo-index">
                    <h1>Home</h1>
                </div>
                <div class="">
                    <div class="cessoes">
                        <h2>Cessões com encerramento iminente</h2>
                        <div>
                            <div class="container-tabela">
                                <TabelaResultadosHomeCessoes v-if="!carregando && !erro" 
                                    :cessoes="cessoes30Dias" 
                                    :dias="30" 
                                    :temPermissao="temPermissao">
                                </TabelaResultadosHomeCessoes>
                            </div>
                            <div class="container-tabela">
                                <TabelaResultadosHomeCessoes v-if="!carregando && !erro" 
                                    :cessoes="cessoes60Dias" 
                                    :dias="60" 
                                    :temPermissao="temPermissao">
                                </TabelaResultadosHomeCessoes>
                            </div>
                            <div class="container-tabela">
                                <TabelaResultadosHomeCessoes v-if="!carregando && !erro" 
                                    :cessoes="cessoes90Dias" 
                                    :dias="90" 
                                    :temPermissao="temPermissao">
                                </TabelaResultadosHomeCessoes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import metaDados from "../../helpers/metaDados";
    import TabelaResultadosHomeCessoes from "../../components/home/TabelaResultadosHomeCessoes.vue";
    import HomeApi from "../../services/HomeApi";
    import CessaoApi from "../../services/CessaoApi";
    import Auth from '../../services/Auth';

    export default {
        name: "Home",
        metaInfo() {
            return {
                title: "Home",
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: "Home - Casa Civil do Estado de Goiás",
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                temPermissao: null,
                status: [],
                cessoes: [],
                cessoes30Dias: [],
                cessoes60Dias: [],
                cessoes90Dias: [],
                carregando: true,
                erro: false,
            };
        },
        components: {
            TabelaResultadosHomeCessoes,
        },
        created() {
            this.homeApi = new HomeApi();
            this.cessaoApi = new CessaoApi();
            let auth = new Auth();
            this.carregarCessoes();
            this.carregarStatus();
            this.temPermissao = auth.temPermissao;
        },
        methods: {
            carregarCessoes: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.homeApi.cancelTokens.getCessoes) {
                        this.homeApi.cancelTokens.getCessoes.cancel();
                    }

                    const dataCessoes = await this.homeApi.getCessoes();

                    this.cessoes = dataCessoes.resultados;

                    this.carregarCessoesParaCadaTabela();

                    this.carregando = false;
                } catch (err) {
                    if (this.homeApi.isCancel(err)) {
                        return;
                    }
                    this.carregando = false;
                    this.erro = true;
                }
            },
            async carregarStatus() {
                try {
                    if (this.carregandoFiltro && !this.erroFiltro) return;

                    this.erroFiltro = false
                    const dadosStatus = await this.cessaoApi.getStatus();
                    this.status = dadosStatus
                } catch (err) {
                    console.log("carregarStatus", err);
                    this.carregandoFiltro = false;
                    this.erroFiltro = true;
                    this.status = [];
                }
            },
            calcularDiasRestantes(elemento) {
                if (elemento.data_termino !== null) {
                    // Convertendo a Data de término da cessão para um objeto Date
                    const dataTerminoObj = new Date(elemento.data_termino);

                    // Obtendo a data atual
                    const dataAtual = new Date();

                    // Calculando a diferença em milissegundos entre as duas datas
                    const diferencaEmMilissegundos = dataTerminoObj - dataAtual;

                    // Convertendo a diferença de milissegundos para dias
                    const diasRestantes = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

                    return diasRestantes;
                }
            },
            carregarCessoesParaCadaTabela () {
                let idStatusEncerrado;
                this.status.map((elemento) => {
                    if(elemento.codigo == 5) {
                        idStatusEncerrado = elemento.id
                    }
                })

                try {                   
                    this.cessoes.map((cessao) => {
                        if(cessao.status_id !== idStatusEncerrado) {
                            if(this.calcularDiasRestantes(cessao) <= 90 && this.calcularDiasRestantes(cessao) > 60) {
                                this.cessoes90Dias.push(cessao);
                            }
                            if (this.calcularDiasRestantes(cessao) <= 60 && this.calcularDiasRestantes(cessao) > 30) {
                                this.cessoes60Dias.push(cessao);
                            }
                            if (this.calcularDiasRestantes(cessao) <= 30 && this.calcularDiasRestantes(cessao) > 0) {
                                this.cessoes30Dias.push(cessao);
                            }
                        }
                    })
                } catch (err) {
                    if (this.homeApi.isCancel(err)) {
                        return;
                    }
                    this.carregando = false;
                    this.erro = true;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (min-width: "1066px") {
        .div-conteudo {
            .index {
                h2 {
                    font-family: "Montserrat-Bold";
                    color: #005516;
                    font-size: 1.3rem;
                    text-align: center;
                }
                .topo-index {
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;
                }
                .grid {
                    display: grid;
                    grid-template: "a b";
                    gap: 1rem;
                }
                .cessoes {
                    width: 100%;
                    margin: 0rem auto;

                    .container-tabela {
                        margin: 1rem auto;
                        border-radius: 1.5rem;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    }
                }
            }
        }
    }
    @media screen and (min-width: "60px") and (max-width: "1065px") {
        .div-conteudo {
            .index {
                .cessoes {
                    width: 100%;
                    margin: 0rem auto;
                }
                h2 {
                    font-family: "Montserrat-Bold";
                    color: #005516;
                    font-size: 1.3rem;
                    text-align: center;
                }
            }
        }
    }
</style>