<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="ConveniosCotasEdit" />
            <h1>{{ convenioCotaForm.id ? "Editar" : "Novo" }} Convênio</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>                
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="item">
                            <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                            <input type="text" placeholder="Digite o nome"
                                v-model="convenioCotaForm.nome" />
                        </div>
                        <div class="item">
                            <label for="selectOrgaoConveniado"><b>{{ possuiAsterisco("Órgão:") }}</b></label>
                            <v-select
                                class="vselect" 
                                v-model="selectedOrgao" 
                                id="selectedOrgao" 
                                :options="paginated"
                                label="nome" 
                                placeholder="Todos"
                                :filterable="false" 
                                @search="onSearch">
                                <li slot="list-footer" class="pagination">
                                    <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                                    <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                                </li>
                            </v-select>
                        </div>
                        <div class="item">
                            <div id="divFaixas">
                                <div class="item multiselect">
                                    <MultiplasFaixas v-model="convenioCotaFormm.faixas_convenios_attributes"></MultiplasFaixas>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Este convênio está ativo?") }}</b></label>
                                <div class="radio-item">
                                    <label for="sim-ativo">Sim</label>
                                    <input type="radio" id="sim-ativo" name="radioOptions"
                                        :value=true v-model="convenioCotaForm.ativo">
                                    <label for="nao-ativo">Não</label>
                                    <input type="radio" id="nao-ativo" name="radioOptions"
                                        :value=false v-model="convenioCotaForm.ativo">
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <label><b>Observação:</b></label>
                            <textarea v-model="convenioCotaForm.observacao" name="body" class="textarea" id="body"
                                placeholder="Escreva uma observação aqui" @input="resize($event)"></textarea>
                        </div>
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ convenioCotaForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import ConvenioCotaApi from "../../services/ConvenioCotaApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";
    import MultiplasFaixas from '../../components/convenios_cotas/MultiplasFaixas.vue';
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: "ConvenioCotaForm",
        props: {
            convenioCota: {
                type: Object,
                required: false,
            },
            faixas: {
                type: Object,
                required: false,
            },
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
            vSelect,
            MultiplasFaixas,
        },
        data() {
            return {
                nomeRotaAtual: this.$router.currentRoute.name,
                carregando: true,
                erro: false,
                msgErros: {
                    nome: '',
                    orgao: '',
                    faixas: '',
                    limite: '',
                    ativo: '',
                    default: '',
                },
                orgaosConveniados: [],
                optionsOrgaosConveniados: [],
                selectedOrgao: null,
                search: '',
                offset: 0,
                limit: 10,
                faixasConvenios: [],
                convenioCotaFormm: this.convenioCota
                    ? {
                        faixas_convenios_attributes: this.convenioCota.faixas_convenios
                            ? this.convenioCota.faixas_convenios
                            : [],
                    }
                    : {
                        faixas_convenios_attributes: [],
                    },
                convenioCotaForm: this.convenioCota
                    ? {
                        id: this.convenioCota.id,
                        nome: this.convenioCota.nome,
                        orgao_id: this.convenioCota.orgao_id,
                        faixas_convenios_attributes: this.convenioCota.faixas_convenios_attributes,
                        ativo: this.convenioCota.ativo,
                        observacao: this.convenioCota.observacao,
                    }
                    : {
                        id: null,
                        nome: null,
                        orgao_id: null,
                        ativo: null,
                        observacao: null,
                        faixas_convenios_attributes: [],
                    },
            };
        },
        created() {
            this.convenioCotaApi = new ConvenioCotaApi();
            this.carregarFaixasConvenios(this.convenioCotaForm.id);
            this.carregarOrgaosConveniados();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            onSearch(query) {
                this.search = query
                this.offset = 0
            },
            async carregarOrgaosConveniados() {
                try {
                    if (this.carregandoFiltro && !this.erroFiltro) return;

                    this.erroFiltro = false

                    const dadosOrgaosConveniados = await this.convenioCotaApi.getOrgaosConveniados();
                    this.orgaosConveniados = dadosOrgaosConveniados

                    this.orgaosConveniados.map((orgao) => {
                        if (orgao.sigla !== null) {
                            orgao.nome = orgao.sigla + " - " + orgao.nome
                        }
                    })

                    if(this.nomeRotaAtual == 'ConveniosCotasEdit') {
                        this.orgaosConveniados.map((orgao) => {
                            if (orgao.id == this.convenioCotaForm.orgao_id) {
                                this.selectedOrgao = orgao;
                            }
                        })
                    }
                } catch (err) {
                    console.log("carregarOrgaosConveniados", err);
                    this.carregandoFiltro = false;
                    this.erroFiltro = true;
                    this.orgaosConveniados = [];
                }
            },
            async carregarFaixasConvenios(idConvenio) {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if(idConvenio !== null) {
                        const dadosFaixasConvenios = await this.convenioCotaApi.getFaixasConvenios(idConvenio);
                        this.faixasConvenios = dadosFaixasConvenios
                    }

                } catch (err) {
                    console.log("carregarFaixasConvenios", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            telaFaixa() {
                var divFaixa = document.getElementById("divFaixas")
                divFaixa.style.visibility = "visible"
            },
            fecharTelaFaixa() {
                //Fechar as telas de tipo de convênio e faixa de convênio
                var divFaixa = document.getElementById("divFaixas")
                divFaixa.style.visibility = "hidden"
            },
            resize(e) {
                e.target.style.height = 'auto'
                e.target.style.height = `${e.target.scrollHeight}px`
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },
            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;

                let flag = this.validarForm(this.convenioCotaForm, this.convenioCotaFormm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    this.msgErros = '';

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.convenioCotaApi.cancelTokens.setConveniosCotas) {
                        this.convenioCotaApi.cancelTokens.setConveniosCotas.cancel();
                    }

                    if (this.selectedOrgao !== null) {
                        this.convenioCotaForm.orgao_id = this.selectedOrgao.id
                    }

                    let data;
                    data = await this.convenioCotaApi.setConveniosCotas(
                        this.convenioCotaForm
                    );

                    this.convenioCotaApi.setConvenioCotaFaixas(data.id, this.convenioCotaForm, this.convenioCotaFormm);

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "ConveniosCotasShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                    //window.location.reload();
                } catch (err) {
                    if (this.convenioCotaApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            validarForm(convenioCotaForm, convenioCotaFormm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros.nome = ''
                this.msgErros.orgao = ''
                this.msgErros.faixas = ''
                this.msgErros.limite = ''
                this.msgErros.ativo = ''
                this.msgErros.default = ''

                //Nome não pode estar em branco
                if (convenioCotaForm.nome == null || convenioCotaForm.nome == '') {
                    this.msgErros.nome = "O campo nome não foi preenchido.";
                    flag = 1;
                }

                //Órgão não pode estar em branco
                if (this.selectedOrgao == null || this.selectedOrgao == '') {
                    this.msgErros.orgao = "O campo órgão não foi preenchido.";
                    flag = 1;
                }

                //Ativo não pode estar em branco
                if (convenioCotaForm.ativo == null) {
                    this.msgErros.ativo = "O campo ativo não foi preenchido.";
                    flag = 1;
                }

                if(convenioCotaFormm.faixas_convenios_attributes.length > 0) {
                    convenioCotaFormm.faixas_convenios_attributes.map((faixa) => {
                        //Faixas não pode estar em branco
                        if (faixa.nome == null || faixa.nome == '') {
                            this.msgErros.faixas = "O campo nome da faixa não foi preenchido.";
                            flag = 1;
                        }
                        //Limite não pode estar em branco
                        if (faixa.limite == null || faixa.limite == '') {
                            this.msgErros.limite = "O campo limite da faixa não foi preenchido.";
                            flag = 1;
                        }
                        //Limite não pode ter mais que 4 dígitos
                        if (faixa.limite > 9999) {
                            this.msgErros.limite = "O campo limite da faixa não pode ter mais de 4 dígitos.";
                            flag = 1;
                        }
                    })
                }

                return flag;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
        },
        computed: {
            filtered() {
                return this.orgaosConveniados.filter((orgao) =>
                    orgao.nome.toLowerCase().includes(this.search.toLowerCase())
                )
            },
            paginated() {
                return this.filtered.slice(this.offset, this.limit + this.offset)
            },
            hasNextPage() {
                const nextOffset = this.offset + this.limit
                return Boolean(
                    this.filtered.slice(nextOffset, this.limit + nextOffset).length
                )
            },
            hasPrevPage() {
                const prevOffset = this.offset - this.limit
                return Boolean(
                    this.filtered.slice(prevOffset, this.limit + prevOffset).length
                )
            },
        }
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">

    div#divFaixas {
        place-self: center;
        width: 100%;
    }

    @media screen and (min-width: "1066px") {
        .caixa-form {
            .flexbox-1 {
                display: grid;
                grid-template-columns: 2.5fr 1fr;
                gap: 2rem;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 3fr 1.5fr;
                gap: 1.5rem;
            }
        }
    }

    @media screen and (min-width: "60px") and (max-width: "1065px") {
        p {
            font-size: calc(.5vw + .7rem);
        }

        .caixa-form {
    
            @media screen and (max-width: "475px") {
                .item {
                    display: grid;
                    margin: 1rem 0;
                    width: -webkit-fill-available;

                    .radio {
                        place-self: center;
                        cursor: default;
                        display: grid;
                        align-items: center;
                        grid-template-columns: repeat(2, 50%);
                        grid-gap: -11px;
                        grid-template-rows: repeat(2,30px);
                        height: -moz-fit-content;
                        height: fit-content;
                        width: -webkit-fit-content;
                        width: -moz-fit-content;
                        width: fit-content;
                        justify-items: center;
                    }

                    .radio-label {
                        grid-column: 1 / 3;
                        margin-bottom: 0;
                    }

                    .radio-item {
                        display: flex;
                        width: fit-content;
                        align-items: center;

                        label {
                            font-size: .8rem;
                        }

                        input {
                            width: fit-content;
                            margin-left: .5rem;
                        }
                    }

                    label {
                        width: fit-content;
                        display: inline;
                        margin-bottom: 0.3rem;
                        color: black;
                        font-family: "Montserrat-Medium";
                        font-size: calc(.5vw + .7rem);
                    }

                    input {
                        width: 100%;
                        height: 2rem;
                        font-family: "Montserrat-Medium";
                        padding-left: 0.5rem;
                        font-size: calc(.5vw + .7rem);
                    }

                    a:link {
                        color: #ffa600;
                    }

                    a:link:hover {
                        color: #e49503;
                    }

                    select {
                        height: 2rem;
                        width: 100%;
                        border-radius: 0.5rem;
                        border: solid 0.1rem;
                        background-color: white;
                        font-family: "Montserrat-Medium";
                        font-size: 0.84rem;
                    }

                    /*  .upload {
                            color: #ffa600;
                            border-radius: 0.5rem;
                            border: #ffa600 solid 0.124rem;
                            display: inline-block;
                            padding: 2px 6px;
                            font-size: 0.9rem;
                            transition: 0.2s;
                            margin-left: 0.4rem;
                            margin-right: 0.4rem;
                            font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                            color: white;
                            background-color: #ffa600;
                        } */
                    .textarea {
                        width: 100%;
                        border-radius: 10px;
                        box-shadow: 0 0 0 1px #000;
                        border: 5px solid transparent;
                        outline: none;
                        padding: 4px;
                        min-height: 150px;
                        box-sizing: border-box;
                        font-family: "Montserrat-Medium";
                        font-size: 0.93rem;
                        color: black;
                    }

                    .textarea:focus {
                        outline: 1px solid !important;
                        box-shadow: 0 0 0 2px black;
                    }
                }
            }

            @media screen and (min-width: "476px"){
                .item {
                    display: grid;
                    margin: 1rem 0;
                    width: -webkit-fill-available;

                    .radio {
                        cursor: default;
                        display: inline-flex;
                        align-items: center;
                        height: fit-content;
                        width: fit-content;
                    }

                    .radio-label {
                        margin-bottom: 0;
                    }

                    .radio-item {
                        display: flex;
                        align-items: center;

                        label {
                            margin: 0 0 0 .8rem;
                            font-size: .8rem;
                        }

                        input {
                            width: fit-content;
                            margin-left: .5rem;
                        }
                    }

                    label {
                        display: inline;
                        margin-bottom: 0.3rem;
                        color: black;
                        font-family: "Montserrat-Medium";
                        font-size: 0.9rem;
                    }

                    input {
                        height: 2rem;
                        font-family: "Montserrat-Medium";
                        padding-left: 0.5rem;
                        font-size: 0.9rem;
                    }

                    a:link {
                        color: #ffa600;
                    }

                    a:link:hover {
                        color: #e49503;
                    }

                    select {
                        height: 2rem;
                        width: 100%;
                        border-radius: 0.5rem;
                        border: solid 0.1rem;
                        background-color: white;
                        font-family: "Montserrat-Medium";
                        font-size: 0.84rem;
                    }

                    /*  .upload {
                            color: #ffa600;
                            border-radius: 0.5rem;
                            border: #ffa600 solid 0.124rem;
                            display: inline-block;
                            padding: 2px 6px;
                            font-size: 0.9rem;
                            transition: 0.2s;
                            margin-left: 0.4rem;
                            margin-right: 0.4rem;
                            font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                            color: white;
                            background-color: #ffa600;
                        } */
                    .textarea {
                        width: 100%;
                        border-radius: 10px;
                        box-shadow: 0 0 0 1px #000;
                        border: 5px solid transparent;
                        outline: none;
                        padding: 4px;
                        min-height: 150px;
                        box-sizing: border-box;
                        font-family: "Montserrat-Medium";
                        font-size: 0.93rem;
                        color: black;
                    }

                    .textarea:focus {
                        outline: 1px solid !important;
                        box-shadow: 0 0 0 2px black;
                    }
                }
            }
        }

        .caixa-form-blocos {
            .item {
                display: grid;
                margin: 1rem 0;
                width: -webkit-fill-available;

                .radio {
                    cursor: default;
                    display: inline-flex;
                    align-items: center;
                    height: fit-content;
                    width: fit-content;
                }

                .radio-label {
                    margin-bottom: 0;
                }

                .radio-item {
                    display: flex;
                    align-items: center;

                    label {
                        margin: 0 0 0 .8rem;
                        font-size: .8rem;
                    }

                    input {
                        width: fit-content;
                        margin-left: .5rem;
                    }
                }

                label {
                    width: fit-content;
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    width: 100%;
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }
            }
        }
    }
</style>
