<template>
    <div>
        <div v-if="erro">
            <SemPermissao></SemPermissao>
        </div>
        <template v-if="!erro">
            <ConvenioCotaForm :convenioCota="null" />
        </template>
    </div>
</template>

<script>
    import ConvenioCotaForm from '../../components/convenios_cotas/ConvenioCotaForm.vue';
    import SemPermissao from "../SemPermissao.vue"
    
    //import ErroResultados from "../../components/ErroResultados";

    export default {
        name: "ConveniosCotasNew",
        data() {
            return {
                erro: false,
            };
        },
        components: {
            SemPermissao,
            ////ErroResultados,
            ConvenioCotaForm,
        },
    };
</script>

<style lang="scss" scoped>
</style>