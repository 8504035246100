<template>
  <div>
    <div class="tela-login">
      <div class="right">
        <h1>Recuperar Senha</h1>
        <form @submit.prevent="handleSubmit">
            <div class="item">
              <label for="email">Digite seu e-mail cadastrado no sistema:</label>
              <input type="email" name="email" id="email" class="form-control" placeholder="E-mail" v-model="email" />
            </div>

            <div class="mensagem">
              <p>Email é obrigatório!</p>
            </div>

            <div class="item">
              <button type="submit" class="btn-enviar">Enviar</button>
            </div>
        </form>
        <button type="button" class="btn-voltar" @click="voltar()">Voltar</button>
      </div>
      <div class="footer-mobile">
        <img
          title="Logo da Casa Civil e Estado de Goiás"
          src="../../assets/imagens/logo-casa-bco.png"
          class="logo"
          alt="Logo da Casa Civil e Estado de Goiás"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import metaDados from "../../helpers/metaDados";

  export default {
    name: 'RecuperarSenha',
    metaInfo() {
      return {
        title: "Movimentação Servidores",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Movimentação Servidores - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
        return {
            email: ''
        }
    },
    methods: {
      async handleSubmit() {
        const data = {
          email: this.email
        };

        let divMensagem = document.getElementsByClassName('mensagem')[0]

        if(this.email == "") {
          divMensagem.style.display = "block";
        }

        axios.post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/sessoes/recuperar_senha`, data)
        .then(
          res => {    
            this.$router.push({
              path: "/sessoes/tela_aviso_email",
              params: {
                response: res,
              }
            });
          }
        ).catch(
          err => {
            console.log(err)
          }
        )
      },
      voltar() {
        history.back();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>