<template>
  <div>
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="topo-header centro">
        <botao-voltar class="voltar" nomeRota="ConveniosCotasShow" />
        <h1>Visualizar Convênio</h1>
      </div>
      <div class="form centro">
        <div class="dados">
          <fieldset>
            <div class="dados">
              <div class="flexbox-1">
                <p class="item">Nome:
                  <span>{{ convenioCota.nome }}</span>
                </p>
                <div v-if="convenioCota.ativo == true" class="item">
                  <p>Ativo:
                    <span>Sim</span>
                  </p>
                </div>
                <div v-else>
                  <p>Ativo:                
                    <span>Não</span>
                  </p>                  
                </div>
              </div>
              <p class="item">Órgão:
                <span>{{ convenioCota.nome_orgao_conveniado }}</span>
              </p>
              <div class="form-faixas item">
                <p>Faixas:</p>
                  <div class="flexbox-1">
                    <template v-for="faixa in convenioCota.faixas_convenios">
                      <template>
                        <fieldset :key="faixa.id" class="faixas">
                          <div class="nome">
                            <p>Nome da faixa: </p>
                            <span>{{ faixa.nome }}</span>
                          </div>
                          <div class="limite">
                            <p>Limite: </p>
                            <span>{{ faixa.limite }}</span>
                          </div>
                        </fieldset>
                      </template>
                    </template>
                  </div>
              </div>
              <p class="item">Observação:
                <span>{{ convenioCota.observacao }}</span>
              </p>
            </div>
          </fieldset>
        </div>
        <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
          <div class="div-center">
            <div :id="convenioCota.id">
              <a :href="'/convenio_cota/' + convenioCota.id + '/edit'">
                <button id="botao-editar">
                  Editar
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer
          :createdByLabel="'Criado por'"
          :criadoPor="convenioCota.criado_por"
          :lastUpdatedByLabel="'Última atualização feita por'"
          :atualizadoPor="convenioCota.atualizado_por"
          :emLabel="' em '"
          :SistemaLabel="'Sistema'"
          :created_at="convenioCota.created_at"
          :updated_at="convenioCota.updated_at"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import ConvenioCotaApi from "../../services/ConvenioCotaApi";
  import { parseISO, format } from "date-fns"
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirConvenio",
    props: {
      convenioCota: Object,
      temPermissao: Object,
    },
    data() {
      return {
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        r: false,

        dadosConvenio: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      }
    },
    created() {
      this.convenioCotaApi = new ConvenioCotaApi();
      this.carregando = false;
    },
    methods: {
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
    },
    components: {
      BotaoVoltar,
      Footer,
    },
  };
</script>

<style lang="scss" scoped>
</style>