<template>
    <div>
        <div v-if="erro">
            <SemPermissao></SemPermissao>
        </div>
        <template v-if="!erro">
            <StatusForm :status="null" />
        </template>
    </div>
</template>

<script>
    import StatusForm from '../../components/status/StatusForm.vue';
    import SemPermissao from "../SemPermissao.vue"
    
    //import ErroResultados from "../../components/ErroResultados";

    export default {
        name: "StatusNew",
        data() {
            return {
                erro: false,
            };
        },
        components: {
            ////ErroResultados,
            SemPermissao,
            StatusForm,
        },
    };
</script>

<style lang="scss" scoped></style>