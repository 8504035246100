<template>
  <div>
    <div class="tela-login">
      <div class="right">
        <h1>Link enviado!</h1>
        <form  @submit.prevent="handleSubmit">
            <div class="item">
                <p>
                  Um link foi enviado para o seu email cadastrado. 
                  Abra este link para redefinir sua senha.
                </p>
                <p class="aviso">
                  Após 24h este link irá expirar!
                </p>
            </div>
        </form>
      </div>
      <div class="footer-mobile">
        <img
          title="Logo da Casa Civil e Estado de Goiás"
          src="../../assets/imagens/logo-casa-bco.png"
          class="logo"
          alt="Logo da Casa Civil e Estado de Goiás"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import metaDados from "../../helpers/metaDados";

  export default {
    name: 'TelaAvisoEmail',
    metaInfo() {
      return {
        title: "Movimentação Servidores",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Movimentação Servidores - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
</style>