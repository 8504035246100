<template>
  <div class="div-conteudo">
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="index centro">
        <div class="topo-index">
          <h1>Convênios</h1>
          <router-link v-if="temPermissao.admin" :to="{
            name: 'ConveniosCotasNew',
          }">
            <span>+</span> Novo Convênio
          </router-link>
        </div>
        <div class="caixa-pesquisa"> <!-- MUDAR ITENS DA PESQUISA -->
          <h2 @click="tooglePesquisa()">
            Pesquisa
            <span>
              <template v-if="!exibirPesquisa">&#9650;</template>
              <template v-else>&#9660;</template>
            </span>
          </h2>
          <transition name="slide">
            <form @submit.prevent="fazerBusca" v-if="!exibirPesquisa">
              <div class = "row2-data">
                <div class="item">
                  <label> Nome: </label>
                  <input type="text" placeholder="Digite o nome" v-model="filtro.nome" />
                </div>
                <div class="item">
                  <label for="selectOrgaoConveniado">Órgão:</label>
                  <v-select
                      class="vselect" 
                      v-model="selectedOrgao" 
                      id="selectOrgao" 
                      :options="paginated"
                      label="nome" 
                      placeholder="Todos"
                      :filterable="false" 
                      @search="onSearch">
                    <li slot="list-footer" class="pagination">
                        <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                        <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                    </li>
                  </v-select>
                </div>
              </div>
              <div class="botoes-pesquisa">
                <div class="bt-pesquisa">
                  <button title="Pesquisar" type="submit">
                    <img src="../../assets/imagens/pesquisa.png" class="pesquisa" alt=" " />
                    Pesquisar
                  </button>
                </div>
                <div class="bt-limpar">
                  <button title="Limpar" @click="limparFiltro()">
                    <img src="../../assets/imagens/icon-eraser.png" class="limpar" alt=" " />
                    Limpar Filtros
                  </button>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="n-div">
          <div class="nresultados" v-show="!carregando">
            <label>Total de Resultados: {{ this.totalResultadosTexto }}</label>
          </div>
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página </label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div v-if="temPermissao.admin || temPermissao.gpd" class="container-tabela">
          <TabelaResultadosConvenios :conveniosCotas="conveniosCotas" 
            :convenioCota="convenioCota" 
            :optionsOrgaosConveniados="optionsOrgaosConveniados" 
            v-if="!carregando && !erro && !erroFiltro"
            @excluir-linha="excluirLinha">
          </TabelaResultadosConvenios>
        </div>
        <div v-else class="container-tabela">
          <TabelaResultadosConveniosUsuario :conveniosCotas="conveniosCotas" 
            :convenioCota="convenioCota" 
            :optionsOrgaosConveniados="optionsOrgaosConveniados" 
            v-if="!carregando && !erro && !erroFiltro"
            @excluir-linha="excluirLinha">
          </TabelaResultadosConveniosUsuario>
        </div>
        <paginate v-if="!carregandoFiltro && !carregando" v-model="pagina" :page-count="pageCount"
          :click-handler="mudouPagina" :prev-text="'Anterior'" :next-text="'Próxima'" :container-class="'className'"
          active-class="active-page"></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import Paginate from "vuejs-paginate";
  import tryParseInt from "../../helpers/tryParseInt";
  import metaDados from "../../helpers/metaDados";
  import TabelaResultadosConvenios from "../../components/convenios_cotas/TabelaResultadosConvenios.vue";
  import TabelaResultadosConveniosUsuario from "../../components/convenios_cotas/TabelaResultadosConveniosUsuario.vue";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import ConvenioCotaApi from "../../services/ConvenioCotaApi";
  import Auth from '../../services/Auth';

  export default {
    name: "ConveniosCotas",
    metaInfo() {
      return {
        title: "Convênios",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa de todos os convênios realizados.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa de todos os convênios realizados.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Convênios - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        temPermissao: null,
        conveniosCotas: [],
        convenioCota: null,
        orgaosConveniados: [],
        optionsOrgaosConveniados: [],
        selectedOrgao: null,
        carregando: true,
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        search: '',
        offset: 0,
        limit: 10,
        filtro: {
          nome: null,
          orgao_id: null,
        },
        exibirPesquisa: true,
        carregandoFiltro: false,
        erroFiltro: false,
      };
    },
    components: {
      TabelaResultadosConvenios,
      TabelaResultadosConveniosUsuario,
      Paginate,
      vSelect
    },
    created() {
      this.convenioCotaApi = new ConvenioCotaApi();
      let auth = new Auth();
      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarConveniosCotas();
      this.temPermissao = auth.temPermissao;
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.nome != from.query.nome ||
          to.query.orgao_id != from.query.orgao_id;
        if (
          from.name == "ConveniosCotas" &&
          to.name == "ConveniosCotas" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarConveniosCotas();
        }
      },
    },
    methods: {
      onSearch(query) {
        this.search = query
        this.offset = 0
      },
      setarOptions() {
        for (let i = 0; i < this.orgaosConveniados.length; i++) {
          this.optionsOrgaosConveniados[i] = {
            id: this.orgaosConveniados[i].id,
            nome: this.orgaosConveniados[i].nome
          }
        }
      },
      tooglePesquisa: function () {
        this.exibirPesquisa = !this.exibirPesquisa;
      },
      async excluirLinha(convenioCotaId) {
        try {
          this.carregando = true;
          this.erro = false;

          if (this.convenioCotaApi.cancelTokens.destroyConveniosCotas) {
            this.convenioCotaApi.cancelTokens.destroyConveniosCotas.cancel();
          }

          await this.convenioCotaApi.destroyConveniosCotas(convenioCotaId);

          this.carregarConveniosCotas();
          this.carregarQtdPorPagina();
          this.carregarValoresFiltro();

        } catch (err) {
          if (this.convenioCotaApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;
        }
      },
      fazerBusca() {
        if (this.selectedOrgao !== null) {
          this.filtro.orgao_id = this.selectedOrgao.id
        }
        this.$router.push({
          name: "ConveniosCotas",
          query: {
            ...this.filtro,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      mudouPagina: function (page) {
        this.pagina = page;

        const filtro = {
          nome: this.getParam("nome"),
          orgao_id: this.getParam("orgao_id"),
        };

        this.$router.push({
          name: "ConveniosCotas",
          query: {
            ...filtro,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      carregarValoresFiltro: async function () {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false
          
          const dadosOrgaosConveniados = await this.convenioCotaApi.getOrgaosConveniados();
          this.orgaosConveniados = dadosOrgaosConveniados

          this.orgaosConveniados.map((orgao) => {
            if (orgao.sigla !== null) {
              orgao.nome = orgao.sigla + " - " + orgao.nome
            }
          })

          this.setarOptions();
        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.orgaosConveniados = [];
        }
      },
      carregarConveniosCotas: async function () {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina = this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);

          this.filtro = {
            nome: this.getParam("nome"),
            orgao_id: this.getParam("orgao_id"),
          };

          if (this.convenioCotaApi.cancelTokens.getConveniosCotas) {
            this.convenioCotaApi.cancelTokens.getConveniosCotas.cancel();
          }

          let data = await this.convenioCotaApi.getConveniosCotas({
            ...this.filtro,
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });

          this.conveniosCotas = data.resultados;
          this.totalResultados = data.total_resultados;
          if (data.total_resultados >= 1000) {
            this.totalResultadosTexto = (data.total_resultados / 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          } else {
            this.totalResultadosTexto = data.total_resultados;
          }
          this.carregando = false;
        } catch (err) {
          if (this.convenioCotaApi.isCancel(err)) {
            return;
          }
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function () {
        this.tooglePesquisa();
        this.filtro = {
          nome: undefined,
          orgao_id: undefined,
        };
        this.selectedOrgao = null
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_orgao",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_orgao");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
      filtered() {
        return this.optionsOrgaosConveniados.filter((orgao) =>
          orgao.nome.toLowerCase().includes(this.search.toLowerCase())
        )
      },
      paginated() {
        return this.filtered.slice(this.offset, this.limit + this.offset)
      },
      hasNextPage() {
        const nextOffset = this.offset + this.limit
        return Boolean(
          this.filtered.slice(nextOffset, this.limit + nextOffset).length
        )
      },
      hasPrevPage() {
        const prevOffset = this.offset - this.limit
        return Boolean(
          this.filtered.slice(prevOffset, this.limit + prevOffset).length
        )
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>