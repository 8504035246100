<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <div v-if="erro">
                <SemPermissao></SemPermissao>
            </div>
            <template v-if="!erro">
                <ExibirOrgao :orgao="orgao" :temPermissao="temPermissao" />
            </template>
        </div>
    </div>
</template>

<script>
    import OrgaoApi from "../../services/OrgaoApi";
    import SemPermissao from "../SemPermissao.vue"
    
    //import ErroResultados from "../../components/ErroResultados";
    import ExibirOrgao from "../../components/orgaos/ExibirOrgao.vue";
    import metaDados from "../../helpers/metaDados";
    import Auth from '../../services/Auth';

    export default {
        name: "OrgaoShow",
        metaInfo() {
            if (!this.orgao) {
                return {};
            }

            let title = this.orgao.nome;
            const description = this.orgao.nome;

            return {
                title,
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "description",
                        name: "description",
                        property: "description",
                        content: description,
                    },
                    {
                        vmid: "og:description",
                        property: "og:description",
                        content: description,
                    },
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    {
                        vmid: "twitter:description",
                        name: "twitter:description",
                        property: "twitter:description",
                        content: description,
                    },
                    {
                        vmid: "twitter:title",
                        name: "twitter:title",
                        property: "twitter:title",
                        content: `${title} - Casa Civil do Estado de Goiás`,
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                temPermissao: null,
                carregando: true,
                erro: false,
                orgao: null,
            };
        },
        components: {
            SemPermissao,
            ////ErroResultados,
            ExibirOrgao,
        },
        created() {
            this.orgaoApi = new OrgaoApi();
            let auth = new Auth();
            this.carregarOrgao();
            this.temPermissao = auth.temPermissao;
        },
        watch: {
            $route: function (to, from) {
                if (to.params.id != from.params.id) {
                    this.carregarOrgao();
                }
            },
        },
        methods: {
            carregarOrgao: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.orgaoApi.cancelTokens.getOrgao) {
                        this.orgaoApi.cancelTokens.getOrgao.cancel();
                    }

                    const dadosOrgao = await this.orgaoApi.getOrgao(
                        this.$route.params.id
                    );
                    this.orgao = dadosOrgao

                    this.carregando = false;
                } catch (err) {
                    if (this.orgaoApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);

                    this.erro = true;
                    this.carregando = false;
                    this.orgao = null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>