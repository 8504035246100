import { render, staticRenderFns } from "./TabelaResultadosHomeCessoes.vue?vue&type=template&id=1f65c08f&scoped=true&"
import script from "./TabelaResultadosHomeCessoes.vue?vue&type=script&lang=js&"
export * from "./TabelaResultadosHomeCessoes.vue?vue&type=script&lang=js&"
import style0 from "./TabelaResultadosHomeCessoes.vue?vue&type=style&index=0&id=1f65c08f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f65c08f",
  null
  
)

export default component.exports