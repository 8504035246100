<template>
  <header>
    <nav>
      <div class="centro topo-menu">
        <div class="versao">
          <span>v{{ version }}</span>
        </div>
        <div class="gridbox">
          <div class="usuario">
            <p>{{ usuario ? usuario.nome : 'TESTE' }}</p>
            <a @click="sair">
              <img src="../assets/imagens/icon-exit.svg" alt="Sair do Sistema" title="sair" class="sair" />
            </a>
          </div>
          <ContadorTempoSessao />
        </div>
      </div>
    </nav>
    <div class="faixa-menu">
      <div class="icon-mov">
        <div class="logo_menu">
          <img class="icon-sismov" src="../assets/imagens/logo-movimentacao.png" alt="" />
        </div>
        <div class="logo_menu">
          <img class="icon-goias" src="../assets/imagens/imagem-menu.png" alt="" />
        </div>
      </div>
      <div class="centro">
        <input id="atv-menu" type="checkbox" />
        <label for="atv-menu">
          <div class="menu">
            <span class="hamburger"></span>
          </div>
        </label>
        <ul id="ulMenu">
          <li v-for="(rotas, meta) in gruposRotas" :key="meta">
            <template v-for="rota in rotas">
                <router-link v-if="rota.meta.raiz" :key="rota.name" :to="{ name: rota.name }" @click.native="menuButtonClick">
                  {{ rota.meta.label }}
                </router-link>
            </template>
          </li>
        </ul>
      </div>          
    </div>
  </header>
</template>

<script>
  import Auth from "../services/Auth";
  import rotasPrivadas from "../router/rotasPrivadas";
  import camelize from "../helpers/camelize";
  import ContadorTempoSessao from "./ContadorTempoSessao.vue";

  const AUTENTICAR_ROUTE = 'Autenticar';
  const get = require("lodash.get");

  export default {
    name: "Menu",
    created() {
      this.auth = new Auth();
    },
    components: {
      ContadorTempoSessao,
    }, 
    data() {
      const auth = new Auth();
      const gruposRotas = rotasPrivadas.reduce((acc, value) => {
        const meta = get(value, "meta");
        if (meta) {
          if (!value.meta.requerPermissao || auth.usuario.funcionalidades.some(func => value.meta.permissoes.includes(func))) {
            if (acc[meta]) {
              acc[meta].push(value);
            } else {
              acc[meta] = [value];
            }
          }
        }
        return acc;
      }, {});
      return {
        gruposRotas,
        version: process.env.VUE_APP_VERSION
      };

    }, 
    methods: {
      menuButtonClick() {
        var inputMenu = document.getElementById("atv-menu")
        setTimeout(() => {
          inputMenu.click()
        }, 500);
      },
      sair() {
        try {
          this.$swal
            .fire({
              title: "Você tem certeza?",
              text: "Informações não salvas serão perdidas",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, sair",
              cancelButtonText: "Não"
            })
            .then(result => {
              if (result.isConfirmed) {
                this.auth.logout();
                this.$router.push({ name: AUTENTICAR_ROUTE });
              }
            });
        } catch (err) {
          console.log(err);
        }
      },
      camelize
    },
    computed: {
      usuario: function () {
        if (this.auth.usuario && this.auth.loggedIn()) {
          return this.auth.usuario;
        }
        return null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 1150px) {
    .gridbox {
      display: grid;
      gap: .3rem;
      align-items: flex-end;
      height: max-content;
      margin-top: .3rem;
    }
    header {
      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .7rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .9rem;
          color: white;
        }

        .sair {
          height: .9rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      .versao {
        margin: .5rem .7rem;
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 100%;
          padding: 0;
          align-items: center;
        }

        span {
          color: white;
          font-size: 0.9rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }
    }

    .faixa-menu {
      background-color: #005516;

      .centro {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70rem;
        height: 3rem;
      }

      .icon-mov {
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: auto;
      }

      .logo_menu {
        height: -webkit-fill-available;
        margin: 1rem;
        .icon-sismov {
          height: 10rem;
          width: max-content;
        }
        .icon-goias {
          height: 7rem;
          width: max-content;
        }
      }

      .menu {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 0.5rem;
        top: 3.5rem;
        z-index: 3;
        cursor: pointer;

        .hamburger {
          display: block;
          background-color: #ffa600;
          width: 1.4rem;
          height: 2px;
          position: relative;
          z-index: 12;
          margin: 1rem auto;
        }

        .hamburger::before,
        .hamburger::after {
          background-color: #ffa600;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.5s ease-in-out;
        }

        .hamburger::before {
          top: 0.4em;
        }

        .hamburger::after {
          bottom: 0.35em;
        }
      }

      input {
        display: none;
      }

      input:checked~nav {
        top: 3rem;
      }

      input:checked~label {
        .hamburger {
          transform: rotate(45deg);
        }

        .hamburger::before,
        .hamburger::after {
          transform: rotate(90deg);
          top: 0;
        }
      }

      input:checked~ul {
        left: 0;
      }

      a {
        img {
          height: 3rem;
        }
      }
      
      ul {
        margin: 0;
        background-color: #005516;
        list-style: none;
        position: absolute;
        padding: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 6.5rem;
        left: -100%;
        transition: 0.2s linear;
        z-index: 1000;

        li {
          margin: 0rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-end;
          gap: .5rem;

          a {
            padding: 0 .7rem;
            color: #efefef;
            text-decoration: none;
            font-size: 1rem;
            font-family: "Montserrat-Medium";
            color: white;
          }

          a:hover {
            text-decoration: none;
            color: #ffa600;
          }
        }

        .divider {
          display: none;
          border: 1px solid rgba(187, 187, 187, 0.946);
          margin-top: 1.7rem;
          height: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: 950px) and (max-width: 1149px) {

    .gridbox {
      display: grid;
      gap: .3rem;
      align-items: flex-end;
      height: max-content;
      margin-top: .3rem;
    }

    header {
      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .7rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .9rem;
          color: white;
        }

        .sair {
          height: .9rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      .versao {
        margin: .5rem .7rem;
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 80rem;
          padding: 0;
          align-items: center;
        }

        span {
          color: white;
          font-size: 0.9rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }
    }

    .faixa-menu {
      background-color: #005516;

      .centro {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70rem;
        height: 4rem;
        padding: .5rem .5rem 0 .5rem;
      }

      .icon-mov {
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: auto;
      }

      .logo_menu {
        height: -webkit-fill-available;
        margin: 1rem;
        .icon-sismov {
          height: 9.5rem;
          width: max-content;
        }
        .icon-goias {
          height: 6rem;
          width: max-content;
        }
      }

      .menu {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 0.5rem;
        top: 3.5rem;
        z-index: 3;
        cursor: pointer;

        .hamburger {
          display: block;
          background-color: #ffa600;
          width: 1.4rem;
          height: 2px;
          position: relative;
          z-index: 12;
          margin: 1rem auto;
        }

        .hamburger::before,
        .hamburger::after {
          background-color: #ffa600;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.5s ease-in-out;
        }

        .hamburger::before {
          top: 0.4em;
        }

        .hamburger::after {
          bottom: 0.35em;
        }
      }

      input {
        display: none;
      }

      input:checked~nav {
        top: 3rem;
      }

      input:checked~label {
        .hamburger {
          transform: rotate(45deg);
        }

        .hamburger::before,
        .hamburger::after {
          transform: rotate(90deg);
          top: 0;
        }
      }

      input:checked~ul {
        left: 0;
      }

      a {
        img {
          height: 3rem;
        }
      }
      
      ul {
        margin: 0;
        background-color: #005516;
        list-style: none;
        position: absolute;
        padding: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 6.5rem;
        left: -100%;
        transition: 0.2s linear;
        z-index: 1000;

        li {
          margin: 0rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-end;
          gap: .2rem;

          a {
            padding: 0 .7rem;
            color: #efefef;
            text-decoration: none;
            font-size: .95rem;
            font-family: "Montserrat-Medium";
            color: white;
          }

          a:hover {
            text-decoration: none;
            color: #ffa600;
          }
        }

        .divider {
          display: none;
          border: 1px solid rgba(187, 187, 187, 0.946);
          margin-top: 1.7rem;
          height: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: '50em') {
    .logo {
      width: 15rem;
      margin-bottom: .5rem;

      .router-link-active {
        border-bottom: solid 0.17rem #efefef;
      }

      .router-link:hover {
        border-bottom: none;
      }
    }

    .router-link-active {
      border-bottom: solid 0.17rem #ffa600;
    }

    .router-link-active:hover {
      border-bottom: solid 0.17rem #ffa600;
    }

    header {
      .faixa-menu {
        .menu {
          display: none;
        }

        ul {
          position: static;
          display: flex;
          background-color: transparent;
          justify-content: flex-start;
          align-self: flex-end;
          width: fit-content;

          li {
            margin-bottom: 0;
          }

          a {
            color: #efefef;
            padding: 0.5rem 1rem .4rem 1rem;
          }

          a:hover {
            border-bottom: #ffa600 solid 0.15rem;
          }
        }

        img {
          height: 4rem;
        }
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 949px) {
    .gridbox {
      display: grid;
      gap: .2rem;
      align-items: flex-end;
      height: max-content;
      margin: .3rem 0rem .3rem 0rem;
    }

    header {
      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .7rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .9rem;
          color: white;
        }

        .sair {
          height: .9rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      .versao {
        margin: .5rem .7rem;
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 80rem;
          padding: 0;
          align-items: center;
        }

        span {
          color: white;
          font-size: 0.9rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }
    }

    .faixa-menu {
      background-color: #005516;

      .centro {
        display: block;
        max-width: 80rem;
        height: 3rem;
        margin: 0;
        padding: 0;
        text-align: -webkit-center;
        align-content: end;
      }

      .icon-mov {
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .logo_menu {
        height: -webkit-fill-available;
        margin: 1rem;
        .icon-sismov {
          width: max-content;
          height: 9.5rem;
        }
        .icon-goias {
          height: 6rem;
          width: max-content;
        }
      }

      .menu {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 0.5rem;
        top: 3.5rem;
        z-index: 3;
        cursor: pointer;

        .hamburger {
          display: block;
          background-color: #ffa600;
          width: 1.4rem;
          height: 2px;
          position: relative;
          z-index: 12;
          margin: 1rem auto;
        }

        .hamburger::before,
        .hamburger::after {
          background-color: #ffa600;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.5s ease-in-out;
        }

        .hamburger::before {
          top: 0.4em;
        }

        .hamburger::after {
          bottom: 0.35em;
        }
      }

      input {
        display: none;
      }

      input:checked~nav {
        top: 3rem;
      }

      input:checked~label {
        .hamburger {
          transform: rotate(45deg);
        }

        .hamburger::before,
        .hamburger::after {
          transform: rotate(90deg);
          top: 0;
        }
      }

      input:checked~ul {
        left: 0;
      }

      a {
        img {
          height: 3rem;
        }
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          margin: 0rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-end;
          gap: .2rem;

          a {
            padding: 0 .7rem;
            color: #efefef;
            text-decoration: none;
            font-size: .95rem;
            font-family: "Montserrat-Medium";
            color: white;
          }

          a:hover {
            text-decoration: none;
            color: #ffa600;
          }
        }

        .divider {
          display: none;
          border: 1px solid rgba(187, 187, 187, 0.946);
          margin-top: 1.7rem;
          height: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: 680px) and (max-width: 799px) {
    .gridbox {
      display: grid;
      gap: .2rem;
      align-items: flex-end;
      height: max-content;
      margin: .3rem 0;
    }

    header {
      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .7rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .9rem;
          color: white;
        }

        .sair {
          height: .9rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      .versao {
        margin: .5rem .7rem;
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 80rem;
          padding: 0;
          align-items: center;
        }

        span {
          color: white;
          font-size: 0.9rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }
    }

    .faixa-menu {
      background-color: #005516;

      .centro {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 80rem;
        height: 4rem;
      }

      .icon-mov {
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .logo_menu {
        height: -webkit-fill-available;
        margin: 1rem;
        .icon-sismov {
          width: max-content;
          height: 9.5rem;
        }
        .icon-goias {
          height: 7rem;
          width: max-content;
          margin-bottom: 1rem;
        }
      }

      .menu {
        border-radius: 50%;
        width: 2rem;
        height: 1rem;
        cursor: pointer;

        .hamburger {
          display: block;
          background-color: #ffa600;
          width: 1.4rem;
          height: 2px;
          position: relative;
          z-index: 12;
          margin: 1rem auto;
        }

        .hamburger::before,
        .hamburger::after {
          background-color: #ffa600;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.5s ease-in-out;
        }

        .hamburger::before {
          top: 0.4em;
        }

        .hamburger::after {
          bottom: 0.35em;
        }
      }

      input {
        display: none;
      }

      input:checked~nav {
        top: 3rem;
      }

      input:checked~label {
        .hamburger {
          transform: rotate(45deg);
        }

        .hamburger::before,
        .hamburger::after {
          transform: rotate(90deg);
          top: 0;
        }
      }

      input:checked~ul {
        left: 0;
      }

      a {
        img {
          height: 3rem;
        }
      }

      ul {
        background-color: #005516;
        list-style: none;
        position: absolute;
        padding: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 21rem;
        left: -100%;
        transition: 0.2s linear;
        z-index: 1000;

        li {
          margin: 2rem auto;
          display: grid;

          a {
            color: #efefef;
            text-decoration: none;
            font-size: 1.1rem;
            font-family: "Montserrat-Medium";
            color: white;
            margin: 1rem 0;
          }

          a:hover {
            text-decoration: none;
            color: #ffa600;
          }
        }

        .divider {
          display: none;
          border: 1px solid rgba(187, 187, 187, 0.946);
          margin-top: 1.7rem;
          height: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: 60px) and (max-width: 679px) {
    header {
      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .7rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .8rem;
          color: white;
        }

        .sair {
          height: .8rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      .versao {
        margin: .5rem .7rem;
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 80rem;
          padding: 0;
          align-items: center;
        }

        span {
          color: white;
          font-size: 0.8rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }
    }

    .faixa-menu {
      background-color: #005516;

      .centro {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 80rem;
        height: 4rem;
      }

      .icon-mov {
        background-color: #efefef;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .logo_menu {
        height: -webkit-fill-available;
        margin: 1rem;
        .icon-sismov {
          width: max-content;
          height: 9.5rem;
        }
        .icon-goias {
          height: 6rem;
          width: max-content;
        }
      }

      .menu {
        border-radius: 50%;
        width: 2rem;
        height: 1rem;
        cursor: pointer;

        .hamburger {
          display: block;
          background-color: #ffa600;
          width: 1.4rem;
          height: 2px;
          position: relative;
          z-index: 12;
          margin: 1rem auto;
        }

        .hamburger::before,
        .hamburger::after {
          background-color: #ffa600;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.5s ease-in-out;
        }

        .hamburger::before {
          top: 0.4em;
        }

        .hamburger::after {
          bottom: 0.35em;
        }
      }

      input {
        display: none;
      }

      input:checked~nav {
        top: 3rem;
      }

      input:checked~label {
        .hamburger {
          transform: rotate(45deg);
        }

        .hamburger::before,
        .hamburger::after {
          transform: rotate(90deg);
          top: 0;
        }
      }

      input:checked~ul {
        left: 0;
      }

      a {
        img {
          height: 3rem;
        }
      }

      ul {
        background-color: #005516;
        list-style: none;
        position: absolute;
        padding: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 26rem;
        left: -100%;
        transition: 0.2s linear;
        z-index: 1000;

        li {
          margin: 2rem auto;
          display: grid;

          a {
            color: #efefef;
            text-decoration: none;
            font-size: 1.1rem;
            font-family: "Montserrat-Medium";
            color: white;
            margin: 1rem 0;
          }

          a:hover {
            text-decoration: none;
            color: #ffa600;
          }
        }

        .divider {
          display: none;
          border: 1px solid rgba(187, 187, 187, 0.946);
          margin-top: 1.7rem;
          height: 2rem;
        }
      }
    }
  }
</style>