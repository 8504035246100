import { render, staticRenderFns } from "./OnusEdit.vue?vue&type=template&id=33f0c630&scoped=true&"
import script from "./OnusEdit.vue?vue&type=script&lang=js&"
export * from "./OnusEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f0c630",
  null
  
)

export default component.exports