<template>
  <div class="fora">
    <div class="topo-header centro">
      <botao-voltar class="voltar" nomeRota="Orgao"/>
      <h1>Visualizar Órgão</h1>
    </div>
    <div class="form centro">
      <div class="dados">
        <fieldset>
          <div class="dados">
            <div class="flexbox-1">
              <p>Nome: 
                <span>{{ orgao.nome }}</span>
              </p>
              <p>Sigla: 
                <span>{{ orgao.sigla }}</span>
              </p>
            </div>
            <div class="flexbox-1">
              <p>UF: 
                <span>{{ orgao.uf }}</span>
              </p>
            </div>
            <div class="flexbox-1">
              <p>Esfera: 
                <span>{{ orgao.esfera }}</span>
              </p>
              <p>Poder: 
                <span>{{ orgao.poder }}</span>
              </p>
            </div>

          </div>
        </fieldset>
      </div>
      <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
        <div class="div-center">
          <div :id="orgao.id">
            <a :href="'/orgao/' + orgao.id + '/edit'">
              <button id="botao-editar">
                Editar 
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer
        :createdByLabel="'Criado por'"
        :criadoPor="orgao.criado_por"
        :lastUpdatedByLabel="'Última atualização feita por'"
        :atualizadoPor="orgao.atualizado_por"
        :emLabel="' em '"
        :SistemaLabel="'Sistema'"
        :created_at="orgao.created_at"
        :updated_at="orgao.updated_at"
      />
    </div>
  </div>  
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import OrgaoApi from "../../services/OrgaoApi";
  import { parseISO, format } from "date-fns"
  import "../../assets/stylesheets/exibir_conteudo_ato.scss";
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirOrgao",
    props: {
      orgao: Object,
      temPermissao: Object,
    },
    data() {
      return{
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        showModalCancelar: false,
        showModalPublicar: false,
        maxCount: 500,
        remainingCountObservacao: 500,
        hasError: false,
        resposta: null,
        observacao: null,
        status: null,
        acao_analise: '',
        motivo_cancelamento: '',
        mensagemTopo: '',
        mensagemAbaixo: '',
        r: false,

        dadosOrgao: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      }
    },
    created() {
      this.OrgaoApi = new OrgaoApi();
    },
    methods: {
      formatarDataHora: function(value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
    },  
    components: { 
      BotaoVoltar,
      Footer, 
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 1066px) {
    .fora {
      .form {
        fieldset {
          .dados {
            .flexbox-1 {
              display: grid;
              grid-template-columns: 1.5fr 1fr;
              gap: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1065px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;
    }
  }

  @media screen and (min-width: 80px) and (max-width: 599px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;
    }
  }
</style>